import { Document } from './document';
import { FirestoreConverter } from './firestoreConverter';
import firebase from 'firebase/app'

export class Product extends Document {
    static create() {
        return new Product({
            name: '',
            code: '',
            price: 0,
            vat: 21
        })
    }
    name!: string;
    code!: string;
    price!: number;
    vat!: number | null;
    
    constructor(init: Partial<Product>) {
        super(init.id!);
        Object.assign(this, init);
    }
}


export class ProductConverter implements FirestoreConverter<Product> {    
    toFirestore(product: Product) {   
        return {
            name: product.name,
            code: product.code,            
            price: product.price,
            vat: product.vat
        }
    }
    fromFirestore(snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>, options: any): Product {
        const data = snapshot.data(options);
        return new Product({
            id: snapshot.id,
            name: data?.name,
            code: data?.code,
            price: data?.price,
            vat: data?.vat
        });
    }
}