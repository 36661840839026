



























import Vue from "vue";
import { mapMutations } from "vuex";

interface State {
    item: any;
    loading: boolean;
    autoCompleteSearchTerm: string | null;
    items: any[];
}
export default Vue.extend({
    name: "Selector",

    props: {
        selectedItem: Object,
        label: String,
        placeholder: String,
        prependIcon: String,
        textProvider: Function,

        query: Function,
        staticItems: Array,
    },

    data: (): State => {
        return {
            item: null,
            loading: false,
            autoCompleteSearchTerm: null,
            items: [],
        };
    },

    watch: {
        selectedItem: {
            immediate: true,
            handler() {
                this.item = this.selectedItem;

                if (!this.staticItems) {
                    if (this.selectedItem) {
                        this.autoCompleteCreateItemForInitialSelection();
                    }
                }
            },
        },

        staticItems: {
            immediate: true,
            handler() {
                if (this.staticItems) {
                    this.items = this.staticItems;
                }
            },
        },

        item: {
            handler() {
                this.$emit("select", this.item);
            },
        },

        autoCompleteSearchTerm: {
            async handler() {
                if (
                    this.autoCompleteSearchTerm &&
                    (!this.item || this.autoCompleteSearchTerm != this.textProvider(this.item))
                ) {
                    await this.autoCompleteFetchData();
                }
            },
        },
    },

    methods: {
        ...mapMutations("alerts", ["addAlert"]),

        async autoCompleteFetchData() {
            try {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                const data = await this.query(this.autoCompleteSearchTerm);
                this.items = this.autoCompleteConvertToItems(data);
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba načítání: " + error.message,
                });
            } finally {
                this.loading = false;
            }
        },

        autoCompleteConvertToItems(data: any[]) {
            const items: any[] = [];

            data.forEach((item) => {
                items.push({
                    text: this.textProvider(item),
                    value: item,
                });
            });

            return items;
        },

        autoCompleteCreateItemForInitialSelection() {
            const filter = this.items.filter(
                (item: any) => item.value == this.selectedItem
            );
            if (filter.length == 0) {
                this.items.push({
                    text: this.textProvider(this.selectedItem),
                    value: this.selectedItem,
                });
            }
        },
    },
});
