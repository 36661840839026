import { Module } from "vuex";
import { db } from '../../firebase';
import firebase from 'firebase/app'
import { Order, OrderConverter } from '../../models/order';
import {
    FETCH_ORDERS,
    SET_ORDERS_METADATA,
    ADD_ORDER,
    UPDATE_ORDER,
    DELETE_ORDER
} from '../mutationTypes'
import { CollectionModuleConfiguration, CollectionModuleFactory, MutationNames } from "../collectionModuleFactory";
import { OrderByDirection } from "@/models/orderBy";

const ordersModule: Module<any, any> = CollectionModuleFactory.create<Order>(new CollectionModuleConfiguration({
    name: 'orders',
    collectionName: 'orders',
    firstOrderByName: 'number',
    firstOrderByDirection: OrderByDirection.DESC,
    mutationNames: new MutationNames({
        ADD_ITEM: ADD_ORDER,
        UPDATE_ITEM: UPDATE_ORDER,
        REMOVE_ITEM: DELETE_ORDER,
        FETCH: FETCH_ORDERS,
        SET_METADATA: SET_ORDERS_METADATA
    }),
    queryFactory: (state: any, rootGetters: any): firebase.firestore.Query => {
        const searchTerm = state.metadata.filters.searchTerm;
        const userId = state.metadata.filters.userId;
        
        let query: any = db.collection('orders');        

        if(userId) {
            query = query.where('userId', '==', userId);
        }        

        if (searchTerm) {
            query = query.where('keywords', 'array-contains', searchTerm.trim().toLowerCase());
        }

        query = query.orderBy(state.metadata.orderBy.name, state.metadata.orderBy.direction.toString());

        return query;
    },
    entityConverter: new OrderConverter(),
    filters: {
        searchTerm: null,
        userId: null
    }   
}));

export default ordersModule;