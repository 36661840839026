//Navigation
export const OPEN_NAV = 'OPEN_NAV';
export const CLOSE_NAV = 'CLOSE_NAV';
export const SET_CONTEXT_COMMANDS = 'SET_CONTEXT_COMMANDS';

//Config
export const SET_APP_CONFIG = 'SET_APP_CONFIG';

//Alert
export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

//User
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FETCH_USERS = 'FETCH_USERS';

//Customers
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const SET_CUSTOMERS_METADATA = 'SET_CUSTOMERS_METADATA';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';

//Products
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const SET_PRODUCTS_METADATA = 'SET_PRODUCTS_METADATA';

//Orders
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const SET_ORDERS_METADATA = 'SET_ORDERS_METADATA';
export const ADD_ORDER = 'ADD_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';

//OrderWizard
export const OW_SET_BUSY = 'OW_SET_BUSY';
export const OW_FETCH_CURRENT_ORDERS = 'OW_FETCH_CURRENT_ORDERS';
export const OW_CREATE_ORDER = 'OW_CREATE_ORDER';
export const OW_UPDATE_ORDER = 'OW_UPDATE_ORDER';
export const OW_DELETE_ORDER = 'OW_DELETE_ORDER';
export const OW_FETCH_CUSTOMER = 'OW_FETCH_CUSTOMER'
export const OW_SELECT_ORDER = 'OW_SELECT_ORDER';
export const OW_SET_CURRENT_CUSTOMER = 'OW_SET_CURRENT_CUSTOMER';
export const OW_SET_CURRENT_SHIPPING_ADDRESS = 'OW_SET_CURRENT_SHIPPING_ADDRESS';