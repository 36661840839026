



















































































































































































































import Vue from "vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { Product } from "../models/product";
import { remove } from 'remove-accents';

interface State {
    headers: any[];
    searchTerm: string | null;
    sortBy: string;
    sortDesc: boolean;
    searchActive: boolean;
    tilesSort: any;
    tilesSortActive: boolean;
    reseting: boolean;
}

export default Vue.extend({
    name: "Products",

    props: {
        type: String,
        itemsPerPage: Number,
        activated: Boolean,
    },

    data: (): State => {
        const headers = [
            { text: "Kód", value: "code" },
            { text: "Název", value: "name" },
            { text: "Cena", value: "price", align: "end", sortable: false },
        ];
        return {
            headers: headers,

            searchTerm: null,
            searchActive: false,

            sortBy: "code",
            sortDesc: false,

            tilesSort: headers[0],
            tilesSortActive: false,

            reseting: false,
        };
    },

    computed: {
        ...mapGetters("products", [
            "canNext",
            "canPrev",
            "loading",
            "orderByName",
            "orderByDesc",
            "currentFilters",
        ]),
        ...mapState("products", ["items"]),
        sortableHeaders(): any[] {
            return this.headers.filter((h) => false != h.sortable);
        },
    },

    watch: {
        activated: {
            async handler() {
                if (this.activated) {
                    //console.log({ id: this._uid, message: "reseting 0" });
                    this.reseting = true;
                    this.$store.dispatch("products/reset");
                    this.$nextTick(async () => {
                        this.reseting = false;
                        //console.log({ id: this._uid, message: "reseting 1" });

                        //console.log({ id: this._uid, message: "fetch 0" });
                        await this.fetch();
                        //console.log({ id: this._uid, message: "fetch 1" });
                    });
                }
            },
        },
        orderByName: {
            handler() {
                //console.log({ id: this._uid, message: "orderByName" });
                const h = this.headers.filter(
                    (h) => h.value == this.orderByName
                )[0];
                this.tilesSort = h;
            },
        },
        orderByDesc: {
            handler() {
                //console.log({ id: this._uid, message: "orderByDesc" });
                this.sortDesc = this.orderByDesc;
            },
        },
        currentFilters: {
            handler() {
                //console.log({ id: this._uid, message: "search" });
                this.searchTerm = this.currentFilters.searchTerm;
            },
        },
        sortBy: {
            async handler() {
                if (this.reseting) {
                    return;
                }
                //console.log({ id: this._uid, message: "sortBy" });
                await this.orderBy();
            },
        },
        sortDesc: {
            async handler() {
                if (this.reseting) {
                    return;
                }
                //console.log({ id: this._uid, message: "sortDesc" });
                await this.orderBy();
            },
        },
        searchTerm: {
            async handler() {
                if (this.reseting) {
                    return;
                }
                //console.log({ id: this._uid, message: "search" });
                await this.fetch();
            },
        },
        tilesSort: {
            handler() {
                this.sortBy = this.tilesSort.value;
            },
        },
        itemsPerPage: {
            async handler() {
                await this.fetch();
            },
        },
    },

    methods: {
        ...mapActions("alerts", ["addAlert"]),

        highlightDecoratorItemName(name: string) {
            let decorated = name;
            if (decorated && this.searchTerm) {
                const hightlightSource = remove(/barva [0-9]+/i.test(this.searchTerm) ? this.searchTerm.replace(/barva\s/i, '') : this.searchTerm);
                const idx = remove(decorated).toLowerCase().indexOf(hightlightSource.toLowerCase());
                if (idx != -1) {
                    decorated =
                        decorated.substring(0, idx) +
                        `<span class="item-name-highlighted">${decorated.substring(idx, idx + hightlightSource.length)}</span>` +
                        decorated.substring(idx + hightlightSource.length);
                }
            }
            return decorated;
        },
        searchInput(input: string) {
            this.searchTerm = input;
        },
        toggleSearch() {
            this.searchTerm = null;
            this.searchActive = !this.searchActive;
        },

        toggleTilesSort() {
            this.tilesSortActive = !this.tilesSortActive;
        },
        toogleSortDesc() {
            this.sortDesc = !this.sortDesc;
        },

        async fetch() {
            try {
                //console.log({ id: this._uid, message: "fetching 0" });
                if (false == this.activated) {
                    return;
                }
                //console.log({ id: this._uid, message: "fetching 1" });
                await this.$store.dispatch("products/fetch", {
                    itemsPerPage: this.itemsPerPage,
                    filters: {
                        searchTerm: this.searchTerm,
                    },
                });
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba načítání: " + error.message,
                });
            }
        },

        async goToPrevious() {
            await this.$store.dispatch("products/goToPrevious");
        },

        async goToNext() {
            await this.$store.dispatch("products/goToNext");
        },

        async orderBy() {
            if (false == this.activated) {
                return;
            }
            await this.$store.dispatch("products/orderBy", {
                sortBy: this.sortBy,
                sortDesc: this.sortDesc,
            });
        },

        selectProduct(item: Product) {
            this.$emit("select", item);
        },
    },

    async mounted() {
        await this.fetch();
    },

    beforeDestroy() {
        this.$store.dispatch("products/reset");
    },
});
