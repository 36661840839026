import { Module } from "vuex";
import { db } from '../../firebase';
import firebase from 'firebase/app'
import { Customer, CustomerConverter } from '../../models/customer';
import {
    FETCH_CUSTOMERS,
    SET_CUSTOMERS_METADATA,
    ADD_CUSTOMER,
    UPDATE_CUSTOMER,
    DELETE_CUSTOMER
} from '../mutationTypes'
import { CollectionModuleConfiguration, CollectionModuleFactory, MutationNames } from "../collectionModuleFactory";

const customersModule: Module<any, any> = CollectionModuleFactory.create<Customer>(new CollectionModuleConfiguration({
    name: 'customers',
    collectionName: 'customers',
    firstOrderByName: 'name',
    firstOrderByDirection: null,
    mutationNames: new MutationNames({
        ADD_ITEM: ADD_CUSTOMER,
        UPDATE_ITEM: UPDATE_CUSTOMER,
        REMOVE_ITEM: DELETE_CUSTOMER,
        FETCH: FETCH_CUSTOMERS,
        SET_METADATA: SET_CUSTOMERS_METADATA
    }),
    queryFactory: (state: any, rootGetters: any): firebase.firestore.Query => {
        const searchTerm = state.metadata.filters.searchTerm;
        const userId = state.metadata.filters.userId;

        let query: any = db.collection('customers');

        query = query.where('userId', '==', userId);

        if (searchTerm) {
            query = query.where('keywords', 'array-contains', searchTerm.trim().toLowerCase());
        }

        query = query.orderBy(state.metadata.orderBy.name, state.metadata.orderBy.direction.toString());

        return query;
    },
    entityConverter: new CustomerConverter(),
    filters: {
        searchTerm: null,
        userId: null
    }
}));

export default customersModule;