import { EditableObject } from "./editableObject";
import { Product } from "./product";

export class OrderItem implements EditableObject<OrderItem> {
    static create(product: Product) {
        return new OrderItem({
            name: product.name,
            code: product.code,
            quantity: 1,
            processed: 1,
            vatRate: product.vat,
            unitPrice: product.price
        })
    }

    name!: string;
    code!: string;
    quantity!: number;
    processed!: number;
    vatRate!: number | null;
    unitPrice!: number;

    constructor(init: Partial<OrderItem>) {
        Object.assign(this, init);
    }
    clone(): OrderItem {
        return new OrderItem({
            name: this.name,
            code: this.code,
            quantity: this.quantity,
            processed: this.processed,
            vatRate: this.vatRate,
            unitPrice: this.unitPrice
        })
    }
    update(item: OrderItem): void {
        this.name = item.name;
        this.code = item.code;
        this.quantity = item.quantity;
        this.processed = item.processed;
        this.vatRate = item.vatRate;
        this.unitPrice = item.unitPrice;
    }
}