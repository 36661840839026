import {Document} from './document';
import { FirestoreConverter } from './firestoreConverter';
import firebase from 'firebase/app'

export enum UserRole {
    SALES = 'sales',
    STOCK = 'stock',
    ADMIN = 'admin'
}

export class UserConfig {
    contract!: string;
    series!: string;
    nextNumber!: number;
    numberLength!: number;    

    constructor(init: Partial<UserConfig>) {   
        Object.assign(this, init);
    }
}

export class User extends Document {    
    name!: string;
    email!: string;
    roles!: string[];
    config!: UserConfig;

    constructor(init: Partial<User>) {        
        super(init.id!);
        Object.assign(this, init);
    }
}

export class UserConverter implements FirestoreConverter<User> {
    toFirestore(user: User) {   
        return {
            name: user.name,
            email: user.email,
            roles: user.roles,
            config: {
                contract: user.config.contract,
                nextNumber: user.config.nextNumber,
                numberLength: user.config.numberLength,
                series: user.config.series
            }            
        }
    }
    fromFirestore(snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>, options: any): User {        
        const data = snapshot.data(options)!;     
        return new User({
            id: snapshot.id,
            name: data.name,
            email: data.email,
            roles: data.roles,
            config: new UserConfig({
                contract: data.config.contract,
                nextNumber: data.config.nextNumber,
                numberLength: data.config.numberLength,
                series: data.config.series
            })
        });
    }
}