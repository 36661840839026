var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"show-select":_vm.showSelect,"headers":_vm.headers,"items":_vm.items,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"server-items-length":1,"mustSort":"","loading":_vm.loading || _vm.orderExporting,"hide-default-footer":"","loading-text":"Prosím čekejte ...","no-data-text":"Zatím tady nic není"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Objednávky")]),_c('v-spacer'),_c('v-spacer'),(
                        _vm.currentUser &&
                        (_vm.currentUser.roles.indexOf('admin') != -1 ||
                            _vm.currentUser.roles.indexOf('invoice') != -1)
                    )?_c('v-autocomplete',{staticClass:"mx-5 shrink",attrs:{"prefix":"","prepend-inner-icon":"mdi-account","hide-details":"","single-line":"","items":_vm.saleUsers},model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}}):_vm._e(),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchActive),expression:"searchActive"}],staticClass:"shrink",attrs:{"value":_vm.searchTerm,"label":"Hledat","hide-details":"","single-line":""},on:{"change":_vm.searchInput}}),_c('v-btn',{attrs:{"icon":"","disabled":_vm.loading || _vm.orderExporting},on:{"click":_vm.toggleSearch}},[(!_vm.searchActive)?_c('v-icon',[_vm._v("mdi-magnify")]):_c('v-icon',[_vm._v("mdi-close")])],1),(
                        _vm.currentUser &&
                        (_vm.currentUser.roles.indexOf('admin') != -1 ||
                            _vm.currentUser.roles.indexOf('invoice') != -1)
                    )?_c('v-btn',{attrs:{"icon":"","disabled":_vm.loading || _vm.orderExporting || _vm.selected.length == 0},on:{"click":_vm.exportSelected}},[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()],1),_c('v-divider')]},proxy:true},{key:"item.number",fn:function(ref){
                    var item = ref.item;
return [(item.state == 0)?_c('span',[_vm._v(_vm._s(item.creatingTag))]):_c('span',[_vm._v(_vm._s(item.number))])]}},{key:"item.date",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.date ? _vm.formatDate(item.date) : "-"))])]}},{key:"item.billingAddress.company",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.billingAddress && item.billingAddress.company ? item.billingAddress.company : "-"))])]}},{key:"item.total.price.withVat",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.total.price.withVat)+",- Kč")])]}},{key:"item.state",fn:function(ref){
                    var item = ref.item;
return [(item.state == 0)?_c('v-chip',{attrs:{"dark":"","small":"","color":"red"}},[_vm._v("Vytváří se")]):(item.state == 1)?_c('v-chip',{attrs:{"small":"","dark":"","color":"amber"}},[_vm._v("Nová")]):(item.state == 2)?_c('v-chip',{attrs:{"dark":"","small":"","color":"orange"}},[_vm._v("Rozpracovaná")]):(item.state == 3)?_c('v-chip',{attrs:{"dark":"","small":"","color":"light-green"}},[_vm._v("Zpracovaná")]):(item.state == 4)?_c('v-chip',{attrs:{"dark":"","small":"","color":"green"}},[_vm._v("Dokončená")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","disabled":_vm.loading || _vm.orderExporting},on:{"click":function($event){return _vm.openViewDialog(item)}}},[_vm._v(" mdi-eye ")]),(_vm.canDeleteOrder)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","disabled":_vm.loading || _vm.orderExporting},on:{"click":function($event){return _vm.openDeleteConfirmationDialog(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"footer"},[_c('v-divider'),_c('div',{staticClass:"text-center"},[_c('v-btn-toggle',{attrs:{"dense":"","group":""}},[_c('v-btn',{attrs:{"disabled":!this.canPrev},on:{"click":_vm.goToPrevious}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-btn',{attrs:{"disabled":!this.canNext},on:{"click":_vm.goToNext}},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)],2),(_vm.currentOrder)?_c('CustomDialog',{attrs:{"title":'Objednávka ' +
            (_vm.currentOrder.number || _vm.currentOrder.creatingTag)},scopedSlots:_vm._u([{key:"toolbar-actions",fn:function(){return [_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":_vm.downloadPdf}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-printer")]),_vm._v(" Tisk ")],1)]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"exporting-progress"},[(_vm.orderExporting)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"green"}}):_vm._e()],1),_c('OrderViewer',{attrs:{"order":_vm.currentOrder}})]},proxy:true}],null,false,2366932596),model:{value:(_vm.viewDialog),callback:function ($$v) {_vm.viewDialog=$$v},expression:"viewDialog"}}):_vm._e(),(_vm.currentOrder)?_c('ConfirmationDialog',{attrs:{"title":'Potvrzení smazání',"backButton":'Zavřít',"confirmButton":'Smazat'},on:{"confirm":function($event){return _vm.deleteOrder(_vm.currentOrder)}},model:{value:(_vm.deleteConfirmationDialog),callback:function ($$v) {_vm.deleteConfirmationDialog=$$v},expression:"deleteConfirmationDialog"}},[_c('div',[_c('p',{staticClass:"font-weight-regular py-2 my-0"},[_vm._v(" Opravdu chcete smazat tuto objednávku? ")]),_c('p',{staticClass:"font-weight-black py-2 my-0"},[_vm._v(" "+_vm._s(_vm.currentOrder.number || _vm.currentOrder.creatingTag)+" ")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }