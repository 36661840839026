

































































































import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import DetailDialog from "./DetailDialog.vue";
import CustomerEditor from "./CustomerEditor.vue";
import AddressViewer from "./AddressViewer.vue";
import Selector from "./Selector.vue";
import { Customer, CustomerConverter } from "../models/customer";
import { ShippingAddress } from "../models/shippingAddress";
import { db } from "../firebase";
import Utils from "../utils";

interface State {
    selectedCustomer: Customer | null;
    selectedShippingAddress: ShippingAddress | null;
    editableCustomer: Customer | null;
    editableCustomerDetailDialog: boolean;
    customerConverter: CustomerConverter;
    busy: boolean;
}

export default Vue.extend({
    name: "CustomerSelector",

    props: {
        customerId: String,
        shippingAddressId: String,
    },

    components: {
        DetailDialog,
        CustomerEditor,
        AddressViewer,
        Selector,
    },

    data: (): State => {
        return {
            selectedCustomer: null,
            selectedShippingAddress: null,
            editableCustomer: null,
            editableCustomerDetailDialog: false,
            customerConverter: new CustomerConverter(),
            busy: false,
        };
    },

    watch: {
        customerId: {
            immediate: true,
            async handler() {
                if (
                    !this.selectedCustomer ||
                    this.selectedCustomer.id != this.customerId
                ) {
                    await this.setSelectedCustomer();
                }
            },
        },
        selectedCustomer: {
            handler() {
                if (this.selectedCustomer) {
                    if (this.selectedCustomer.id != this.customerId) {
                        this.$emit("customerSelected", this.selectedCustomer);
                        this.selectedShippingAddress = null;
                    }
                } else {
                    this.$emit("customerSelected", null);
                    this.selectedShippingAddress = null;
                }
            },
        },

        shippingAddressId: {
            handler() {
                if (
                    !this.selectedShippingAddress ||
                    this.selectedShippingAddress.id != this.shippingAddressId
                ) {
                    this.setSelectedShippingAddress();
                }
            },
        },
        selectedShippingAddress: {
            handler() {
                if (this.selectedShippingAddress) {
                    if (
                        this.selectedShippingAddress.id !=
                        this.shippingAddressId
                    ) {
                        this.$emit(
                            "shippingAddressSelected",
                            this.selectedShippingAddress
                        );
                    }
                } else {
                    this.$emit("shippingAddressSelected", null);
                }
            },
        },

        busy: {
            handler() {
                this.$emit("busy", this.busy);
            },
        },
    },

    computed: {
        ...mapGetters("user", ["currentUserId"]),
        shippingAddresses(): any[] {
            const arr: any[] = [];

            if (this.selectedCustomer) {
                arr.push({
                    text: "Stejná jako fakturační",
                    value: null,
                });

                this.selectedCustomer.shippingAddresses.forEach(
                    (shippingAddress: ShippingAddress) => {
                        arr.push({
                            text: `${shippingAddress!.name}, ${
                                shippingAddress!.address.street
                            } ${shippingAddress!.address.city}`,
                            value: shippingAddress,
                        });
                    }
                );
            }

            return arr;
        },
    },

    methods: {
        ...mapActions("alerts", ["addAlert"]),

        async setSelectedCustomer() {
            try {
                this.busy = true;
                if (this.customerId) {
                    const doc = await db
                        .collection("customers")
                        .doc(this.customerId)
                        .withConverter<Customer>(this.customerConverter)
                        .get();

                    this.selectedCustomer = doc.exists ? doc.data()! : null;
                } else {
                    this.selectedCustomer = null;
                }

                this.setSelectedShippingAddress();
            } finally {
                this.busy = false;
            }
        },

        setSelectedShippingAddress() {
            if (
                null == this.shippingAddressId ||
                null == this.selectedCustomer
            ) {
                this.selectedShippingAddress = null;
            } else {
                this.selectedShippingAddress = Utils.firstOrDefault(
                    this.selectedCustomer.shippingAddresses,
                    (shippingAddress: ShippingAddress) =>
                        shippingAddress.id == this.shippingAddressId,
                    null
                );
            }
        },

        async searchCustomers(searchTerm: string) {
            try {
                const result = [];

                const data = await db
                    .collection("customers")
                    .where("userId", "==", this.currentUserId)
                    .where(
                        "keywords",
                        "array-contains",
                        searchTerm.trim().toLowerCase()
                    )
                    .orderBy("name", "desc")
                    .limit(10)
                    .withConverter<Customer>(this.customerConverter)
                    .get();

                result.push(...data.docs.map((doc) => doc.data()));
                return result;
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Načtení se nepodařilo: " + error.message,
                    timeout: 3000,
                });
            }
        },

        customerTextProvider(customer: Customer) {
            return `${customer!.name} (${customer!.billingAddress!.subId}), ${
                customer!.email
            }`;
        },

        createCustomer() {
            this.editableCustomer = Customer.create(this.currentUserId);
            this.editableCustomerDetailDialog = true;
        },

        editCustomer() {
            this.editableCustomer = this.selectedCustomer;
            this.editableCustomerDetailDialog = true;
        },

        async saveCustomerHandler(customer: Customer) {
            try {
                this.busy = true;

                const snapshot = await db
                    .collection("customers")
                    .where(
                        "billingAddress.subId",
                        "==",
                        customer.billingAddress.subId
                    )
                    .get();
                if (!customer.id && !snapshot.empty) {
                    throw new Error(
                        `Zákazník s IČO ${customer.billingAddress.subId} již existuje.`
                    );
                }

                if (customer.id) {
                    const doc = await db
                        .collection("customers")
                        .doc(customer.id)
                        .withConverter(this.customerConverter)
                        .set(customer, { merge: true });
                    this.selectedCustomer!.update(customer);
                    this.setSelectedShippingAddress();
                    this.$emit("customerUpdated", this.selectedCustomer);
                    this.$emit(
                        "shippingAddressUpdated",
                        this.selectedShippingAddress
                    );
                } else {
                    const doc = await db
                        .collection("customers")
                        .withConverter(this.customerConverter)
                        .add(customer);
                    customer.id = doc.id;
                    this.selectedCustomer = customer;
                }
            } finally {
                this.busy = false;
            }
        },
    },
});
