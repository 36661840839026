





































































import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
    name: "Navigation",

    computed: {
        navIcon() {
            return this.$route.path != "/" ? "mdi-arrow-left" : "mdi-menu";
        },

        ...mapGetters("config", ["appName"]),

        ...mapGetters("user", ["isAuthenticated", "currentUserDisplayName"]),

        ...mapGetters(["contextCommands"]),
    },

    methods: {
        navigate() {
            if (this.$route.path == "/") {
                this.$store.dispatch("openNav");
            } else {
                this.$router.back();
            }
        },
        logout() {
            this.$store.dispatch("user/logout");
        },
    },
});
