export enum OrderByDirection {
    ASC = 'asc',
    DESC = 'desc'
}

export class OrderBy {
    name!: string;
    direction!: OrderByDirection;

    constructor(init: Partial<OrderBy>) {
        Object.assign(this, init);
    }
}