import Vue from 'vue'
import Vuex from 'vuex'

import alerts from './modules/alerts';
import user from './modules/user';
import customers from './modules/customers';
import products from './modules/products';
import orders from './modules/orders';
import orderProcessing from './modules/orderProcessing';
import config from './modules/config';

import {
    OPEN_NAV,
    CLOSE_NAV,
    SET_CONTEXT_COMMANDS
} from './mutationTypes'
import { ContextCommand } from '@/models/contextCommand';

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        alerts,
        user,
        customers,
        products,
        orders,
        orderProcessing,
        config
    },
    state: {
        navOpen: false,
        contextCommands: [] as ContextCommand[]
    },
    getters: {
        hasContextCommands(state) {
            return state.contextCommands.length > 0;
        },
        contextCommands(state) {
            return state.contextCommands;
        }
    },
    mutations: {
        [OPEN_NAV](state: any) {
            state.navOpen = true;
        },
        [CLOSE_NAV](state: any) {
            state.navOpen = false;
        },
        [SET_CONTEXT_COMMANDS](state, commands) {
            state.contextCommands = commands;
        }
    },
    actions: {
        openNav({ commit }) {
            commit(OPEN_NAV);
        },
        closeNav({ commit }) {
            commit(CLOSE_NAV);
        },
        setContextCommands({ commit }, commands: ContextCommand[]) {
            commit(SET_CONTEXT_COMMANDS, commands);
        }
    }
})
