import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify/index';
import { auth } from './firebase';
import './styles/materialdesignicons.css' 

Vue.config.productionTip = false


let app: Vue
auth.onAuthStateChanged((user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }

  if (user) {
    store.dispatch('user/fetchUser', user)
  }
})
