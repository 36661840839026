












































































































































































































































import Vue from "vue";
import { mapActions } from "vuex";
import { Customer } from "../models/customer";
import { EditableObject } from "../models/editableObject";
import { ShippingAddress } from "../models/shippingAddress";
import AddressEditor from "./AddressEditor.vue";
import ConfirmationDialog from "./ConfirmationDialog.vue";
import DetailDialog from "./DetailDialog.vue";
import AddressViewer from "./AddressViewer.vue";
import Utils from "../utils";
import { callBackendFunction } from "../firebase";

interface State {
    editableCustomer: Customer | null;
    shippingAddressesHeader: any[];
    currentShippingAddress: ShippingAddress | null;
    detailDialog: boolean;
    deleteConfirmationDialog: boolean;
    regularExpressions: object;
    subIdForSearch: string | null;
    aresLoading: boolean;
}

export default Vue.extend({
    name: "CustomerEditor",

    props: {
        customer: {
            type: Customer,
            required: true,
        },
    },

    data: (): State => {
        return {
            editableCustomer: null,

            shippingAddressesHeader: [
                { text: "Název", value: "name" },
                { text: "Adresa", value: "address", filterable: false },
                {
                    text: "",
                    value: "actions",
                    filterable: false,
                    sortable: false,
                    align: "end",
                },
            ],
            currentShippingAddress: null,
            detailDialog: false,
            deleteConfirmationDialog: false,

            regularExpressions: {
                email: Utils.commonRegularExpressions("email"),
                phone: Utils.commonRegularExpressions("phone"),
                ico: Utils.commonRegularExpressions("ico"),
                dic: Utils.commonRegularExpressions("dic"),
            },

            subIdForSearch: null,
            aresLoading: false,
        };
    },

    watch: {
        customer: {
            immediate: true,
            handler(value) {
                this.editableCustomer = value;
            },
        },

        subIdForSearch: {
            async handler() {
                await this.loadCustomerFromAres(this.subIdForSearch);
                this.subIdForSearch = null;
            },
        },
    },

    components: {
        AddressEditor,
        AddressViewer,
        ConfirmationDialog,
        DetailDialog,
    },

    methods: {
        ...mapActions("alerts", ["addAlert"]),

        subIdForSearchInput(input: string) {
            this.subIdForSearch = input;
        },

        async loadCustomerFromAres(subId: string | null) {
            try {
                this.aresLoading = true;
                if (subId) {
                    const result = await callBackendFunction(
                        "loadCustomerFromAres",
                        {
                            subId,
                        }
                    );

                    this.editableCustomer!.name = result.data.company;
                    this.editableCustomer!.billingAddress.company =
                        result.data.company;
                    this.editableCustomer!.billingAddress.subId =
                        result.data.subId;
                    this.editableCustomer!.billingAddress.address.street =
                        result.data.address.street;
                    this.editableCustomer!.billingAddress.address.streetExtend =
                        result.data.address.streetExtend;
                    this.editableCustomer!.billingAddress.address.city =
                        result.data.address.city;
                    this.editableCustomer!.billingAddress.address.zip =
                        result.data.address.zip;
                    this.editableCustomer!.billingAddress.address.country =
                        result.data.address.country;
                }
            } catch (error) {                
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Načtení dat klienta se nezdařilo",
                    timeout: 3000,
                });
            } finally {
                this.aresLoading = false;
            }
        },

        addShippingAddress() {
            this.openDetailDialog(ShippingAddress.create());
        },

        async saveShippingAddressHandler(
            shippingAddress: ShippingAddress
        ): Promise<void> {
            (this
                .currentShippingAddress as EditableObject<ShippingAddress>).update(
                shippingAddress
            );
            const idx = this.editableCustomer!.shippingAddresses.indexOf(
                this.currentShippingAddress!
            );
            if (idx == -1) {
                this.editableCustomer!.shippingAddresses.push(shippingAddress);
            }
        },

        async deleteShippingAddress(shippingAddress: ShippingAddress) {
            this.editableCustomer!.shippingAddresses.splice(
                this.editableCustomer!.shippingAddresses.indexOf(
                    shippingAddress
                ),
                1
            );
        },

        openDetailDialog(shippingAddress: ShippingAddress) {
            this.currentShippingAddress = shippingAddress;
            this.detailDialog = true;
        },

        openDeleteConfirmationDialog(shippingAddress: ShippingAddress) {
            this.currentShippingAddress = shippingAddress;
            this.deleteConfirmationDialog = true;
        },
    },
});
