



















































































































































































































































































































































































































































































import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import firebase from "firebase/app";
import { db } from "../firebase";
import { Order, OrderConverter, OrderState } from "../models/order";
import { OrderByDirection } from "../models/orderBy";
import CustomDialog from "./CustomDialog.vue";
import OrderItems from "./OrderItems.vue";
import Products from "./Products.vue";
import { User } from "../models/user";
import { Product } from "../models/product";
import { OrderItem } from "../models/orderItem";
import { EditableObject } from "../models/editableObject";
import Utils from "../utils";
import { UPDATE_ORDER } from "../store/mutationTypes";

interface State {
    window: any;
    headers: any[];
    searchTerm: string | null;
    sortBy: string;
    sortDesc: boolean;
    searchActive: boolean;
    saleUsers: any[];
    userId: string | null;
    showCompleted: boolean;
    subscription: any;
    orders: Order[];
    currentOrder: Order | null;
    ordersConverter: OrderConverter;
    orderDialog: boolean;
    orderSaving: boolean;
    orderExporting: boolean;
    processingOrder: Order | null;
    processingOrderDirty: boolean;
    productsSelectionDialog: boolean;
    productsSelectionType: string | null;
}

export default Vue.extend({
    name: "OrderProcess",

    components: {
        CustomDialog,
        OrderItems,
        Products,
    },

    data: (): State => {
        return {
            window: {
                width: 0,
                height: 0,
            },
            headers: [
                { text: "Číslo", value: "number" },
                { text: "Stav", value: "state" },
                {
                    text: "Obchodník",
                    value: "userDisplayName",
                    sortable: false,
                },
                {
                    text: "Zákazník",
                    value: "billingAddress.company",
                    sortable: false,
                },
                {
                    text: "",
                    value: "actions",
                    filterable: false,
                    sortable: false,
                    align: "end",
                },
            ],
            searchTerm: null,
            searchActive: false,

            showCompleted: false,

            sortBy: "number",
            sortDesc: true,

            saleUsers: [],
            userId: null,

            subscription: null,
            orders: [],
            currentOrder: null,
            ordersConverter: new OrderConverter(),
            orderDialog: false,
            orderSaving: false,
            orderExporting: false,
            processingOrder: null,
            processingOrderDirty: false,
            productsSelectionDialog: false,
            productsSelectionType: null,
        };
    },

    computed: {
        ...mapGetters("orderProcessing", ["canNext", "canPrev", "loading"]),
        ...mapState("orderProcessing", ["items"]),
        ...mapState("user", ["currentUser"]),
        ...mapGetters("config", ["apiKey"]),

        listItemsPerPage(): number {
            const rowHeightApproxPlusReserve = 50;
            const otherHeights = 4 * 48; // nav + table header + table footer + paddings
            return Math.floor(
                (this.window.height - otherHeights) / rowHeightApproxPlusReserve
            );
        },

        orderDetailContainerHeight(): number {
            const otherHeigths = 48 + 4 + 60; //topbar + progress + footer
            return Math.floor(this.window.height - otherHeigths);
        },

        isOrderReady(): boolean {
            return this.currentOrder!.state == OrderState.READY;
        },

        isOrderProcessing(): boolean {
            return this.currentOrder!.state == OrderState.PROCESSING;
        },

        isOrderProcessed(): boolean {
            return this.currentOrder!.state == OrderState.PROCESSED;
        },

        isOrderExported(): boolean {
            return this.currentOrder!.state == OrderState.EXPORTED;
        },

        currentOrderStateDisplayInfo(): { text: string; color: string } {
            const stateDisplayInfo = {
                text: "",
                color: "amber",
            };

            if (this.currentOrder) {
                switch (this.currentOrder.state) {
                    case OrderState.READY:
                        stateDisplayInfo.text = "Nová";
                        stateDisplayInfo.color = "amber";
                        break;
                    case OrderState.PROCESSING:
                        stateDisplayInfo.text = "Rozpracovaná";
                        stateDisplayInfo.color = "orange";
                        break;
                    case OrderState.PROCESSED:
                        stateDisplayInfo.text = "Zpracovaná";
                        stateDisplayInfo.color = "light-green";
                        break;
                    case OrderState.EXPORTED:
                        stateDisplayInfo.text = "Dokončená";
                        stateDisplayInfo.color = "green";
                        break;
                }
            }
            return stateDisplayInfo;
        },

        orderItemsViewType(): string {
            return this.processingOrder ? "processing" : "readonly-processed";
        },
    },

    watch: {
        currentUser: {
            immediate: true,
            async handler() {
                await this.getUsersId();
            },
        },
        sortBy: {
            async handler() {
                await this.orderBy();
            },
        },
        sortDesc: {
            async handler() {
                await this.orderBy();
            },
        },
        searchTerm: {
            async handler() {
                await this.fetch();
            },
        },
        showCompleted: {
            async handler() {
                await this.fetch();
            },
        },
        userId: {
            async handler() {
                await this.fetch();
            },
        },
        listItemsPerPage: {
            async handler() {
                if (this.userId) {
                    await this.fetch();
                }
            },
        },
    },

    methods: {
        ...mapActions("alerts", ["addAlert"]),

        searchInput(input: string) {
            this.searchTerm = input;
        },
        toggleSearch() {
            this.searchTerm = null;
            this.searchActive = !this.searchActive;
        },

        async getUsersId() {
            try {
                if (this.currentUser) {
                    await this.$store.dispatch(
                        "user/fetchUsersByRole",
                        "sales"
                    );
                    this.saleUsers.push(
                        {
                            value: null,
                            text: "Všichni",
                        },
                        ...this.$store.state.user.users.map((user: User) => ({
                            value: user.id,
                            text: user.name,
                        }))
                    );
                    this.userId = this.saleUsers[0].value;
                    await this.fetch();
                }
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba načítání: " + error.message,
                });
            }
        },

        async goToPrevious() {
            try {
                await this.$store.dispatch("orderProcessing/goToPrevious");
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba načítání: " + error.message,
                });
            }
        },

        async goToNext() {
            try {
                await this.$store.dispatch("orderProcessing/goToNext");
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba načítání: " + error.message,
                });
            }
        },

        async orderBy() {
            try {
                await this.$store.dispatch("orderProcessing/orderBy", {
                    sortBy: this.sortBy,
                    sortDesc: this.sortDesc,
                });
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba načítání: " + error.message,
                });
            }
        },

        async fetch() {
            try {
                await this.$store.dispatch("orderProcessing/fetch", {
                    itemsPerPage: this.listItemsPerPage,
                    filters: {
                        searchTerm: this.searchTerm,
                        userId: this.userId,
                        showCompleted: this.showCompleted,
                    },
                });
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba načítání: " + error.message,
                });
            }
        },

        async refresh() {
            try {
                await this.$store.dispatch("orderProcessing/refresh");
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba načítání: " + error.message,
                });
            }
        },

        openOrderDialog(item: Order) {
            this.currentOrder = item;
            this.processingOrder = null;
            this.processingOrderDirty = false;
            if (this.currentOrder.state == OrderState.PROCESSING) {
                this.processingOrder = (this
                    .currentOrder as EditableObject<Order>).clone();
            }

            this.orderDialog = true;
        },

        closeOrderDialogOnError() {
            this.currentOrder = null;
            this.processingOrder = null;
            this.processingOrderDirty = false;
            this.orderDialog = false;
        },

        copyCurrentOrderToProcessingOrder() {
            this.processingOrder = (this
                .currentOrder as EditableObject<Order>).clone();
            this.processingOrderDirty = false;
        },

        /**
         * Save in transaction for ensure existence of order during saving.
         * Can pass validate method for custom validation with exception throwning on invalid state
         */
        async saveOrderWithTransaction(
            id: string,
            data: any,
            validate?: (data: any) => void
        ) {
            await db.runTransaction(
                async (transaction: firebase.firestore.Transaction) => {
                    const doc = await transaction.get(
                        db.collection("orders").doc(id)
                    );
                    if (doc.exists) {
                        if (validate) {
                            validate(doc.data());
                        }
                        transaction.set(doc.ref, data, { merge: true });
                    } else {
                        throw new Error("objednávka byla smazána");
                    }
                }
            );
        },

        async startProcessingAllReadyOrders() {
            try {
                const snap = await db
                    .collection("orders")
                    .where("state", "==", OrderState.READY)
                    .withConverter<Order>(this.ordersConverter)
                    .get();
                const identities = [];
                const failed = [];
                for (let i = 0; i < snap.size; i++) {
                    try {
                        const doc = snap.docs[i];
                        const order = doc.data();

                        await this.saveOrderWithTransaction(order.id, {
                            state: OrderState.PROCESSING,
                        });

                        order.state = OrderState.PROCESSING;
                        this.$store.commit(
                            `orderProcessing/${UPDATE_ORDER}`,
                            order
                        );

                        identities.push({
                            id: doc.id,
                            number: doc.data()!.number!,
                        });
                    } catch (error) {
                        failed.push(snap.docs[i].data().number);
                    }
                }

                await this.printOrders(identities);

                if (failed.length) {
                    this.addAlert({
                        type: "warning",
                        color: "orange",
                        message: `Zahájení některých objednávek selhalo: ${failed.join(
                            ", "
                        )}`,
                    });
                }
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message:
                        "Nelze zahájit zpracování objednávek: " + error.message,
                });
            }
        },

        async startProcessingOrder() {
            try {
                this.orderSaving = true;

                await this.saveOrderWithTransaction(this.currentOrder!.id, {
                    state: OrderState.PROCESSING,
                });

                this.currentOrder!.state = OrderState.PROCESSING;

                await this.printOrders([
                    {
                        id: this.currentOrder!.id,
                        number: this.currentOrder!.number!,
                    },
                ]);
                this.copyCurrentOrderToProcessingOrder();
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: `Nelze zahájit zpracování objednávky: ${error.message}.`,
                    timeout: 5000,
                });
                this.closeOrderDialogOnError();
            } finally {
                this.orderSaving = false;
            }
        },

        async saveProcessingChanges() {
            try {
                this.orderSaving = true;

                await this.saveOrderWithTransaction(
                    this.processingOrder!.id,
                    this.ordersConverter.toFirestore(this.processingOrder!)
                );

                (this.currentOrder as EditableObject<Order>).update(
                    this.processingOrder!
                );
                this.processingOrderDirty = false;
                this.orderDialog = false;
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Nelze uložit objednávku: " + error.message,
                });
            } finally {
                this.orderSaving = false;
            }
        },

        async endProcessingOrder() {
            try {
                this.orderSaving = true;

                this.processingOrder!.state = OrderState.PROCESSED;
                await this.saveOrderWithTransaction(
                    this.processingOrder!.id,
                    this.ordersConverter.toFirestore(this.processingOrder!),
                    (data: any) => {
                        if (data.state != OrderState.PROCESSING) {
                            throw new Error("objednávka má neočekávaný stav");
                        }
                    }
                );

                (this.currentOrder as EditableObject<Order>).update(
                    this.processingOrder!
                );
                this.processingOrder = null;
                this.processingOrderDirty = false;
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: `Nelze zpracovat objednávku: ${error.message}.`,
                    timeout: 5000,
                });
                this.closeOrderDialogOnError();
            } finally {
                this.orderSaving = false;
            }
        },

        async restartProcessingOrder() {
            try {
                this.orderSaving = true;

                await this.saveOrderWithTransaction(
                    this.currentOrder!.id,
                    { state: OrderState.PROCESSING },
                    (data: any) => {
                        if (data.state != OrderState.PROCESSED) {
                            throw new Error(
                                "objednávka má neočekávaný stav (pravděpodně byla exportována)"
                            );
                        }
                    }
                );

                this.currentOrder!.state = OrderState.PROCESSING;
                this.processingOrder = (this
                    .currentOrder as EditableObject<Order>).clone();
                this.processingOrderDirty = false;
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: `Nelze vrátit objednávku do zpracování: ${error.message}.`,
                    timeout: 5000,
                });
                this.closeOrderDialogOnError();
            } finally {
                this.orderSaving = false;
            }
        },

        async printDeliveryNote() {
            try {
                this.orderExporting = true;
                await Utils.exportOrdersToPdf([{
                        id: this.currentOrder!.id,
                        number: this.currentOrder!.number!,
                    }], "deliveryNote", this.apiKey);
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba exportu: " + error.message,
                });
            } finally {
                this.orderExporting = false;
            }
        },

        async printOrders(identities: { id: string; number: string }[]) {
            try {
                this.orderExporting = true;
                await Utils.exportOrdersToPdf(identities, "stock", this.apiKey);
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba exportu: " + error.message,
                });
            } finally {
                this.orderExporting = false;
            }
        },

        async openProductsSelection(selectionType: string) {
            await this.$store.dispatch("products/reset");
            this.productsSelectionType = selectionType;
            this.productsSelectionDialog = true;
        },

        addItem(item: Product) {
            if (this.productsSelectionType == "priceable") {
                const orderItem = OrderItem.create(item);
                this.processingOrder!.priceableItems.push(orderItem);
            } else {
                const orderItem = OrderItem.create(item);
                this.processingOrder!.freeItems.push(orderItem);
            }

            this.processingOrder!.recomputePrice();
            this.productsSelectionType = null;
            this.productsSelectionDialog = false;
            this.processingOrderDirty = true;
        },

        orderItemProcessedChanged() {
            this.processingOrder!.recomputePrice();
            this.processingOrderDirty = true;
        },

        updateNote(note: string) {
            this.processingOrder!.note = note;
            this.processingOrderDirty = true;
        },

        resetAllItemsInOrder() {
            this.processingOrder!.priceableItems.forEach((item: OrderItem) => {
                item.processed = 0;
            });
            this.processingOrder!.freeItems.forEach((item: OrderItem) => {
                item.processed = 0;
            });
            this.processingOrderDirty = true;
        },

        subscribeChanges() {
            this.subscription = {
                unsubscribe: db
                    .collection("orders")
                    .where("state", ">=", OrderState.READY)
                    .where("state", "<", OrderState.EXPORTED)
                    .orderBy("state", OrderByDirection.ASC)
                    .withConverter<Order>(this.ordersConverter)
                    .onSnapshot(this.onChange, this.onError),
                init: false,
            };
        },

        onChange(snapshot: firebase.firestore.QuerySnapshot<Order>) {
            if (!this.subscription.init) {
                this.subscription.init = true;
                return;
            }

            if (
                snapshot
                    .docChanges()
                    .filter((ch) => ch.type == "added" || ch.type == "removed")
                    .length > 0
            ) {
                this.addAlert({
                    type: "info",
                    color: "blue",
                    message: "Objednávky aktualizovány",
                    timeout: 5000,
                });

                this.refresh();
            }
        },

        onError(error: firebase.firestore.FirestoreError) {
            this.addAlert({
                type: "error",
                color: "red",
                message: "Chyba načítání: " + error.message,
            });
        },

        unsubscribeChanges() {
            this.subscription.unsubscribe();
        },

        handleResize(): any {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    },

    created() {
        this.subscribeChanges();
    },

    mounted() {
        this.handleResize();
    },

    destroyed() {
        this.unsubscribeChanges();
    },
});
