


















import Vue from "vue";
import { mapActions } from "vuex";
import TopBar from "./components/TopBar.vue";
import SideMenu from "./components/SideMenu.vue";
import Alerts from "./components/Alerts.vue";
import { db } from "./firebase";

export default Vue.extend({
    name: "App",
    components: { TopBar, SideMenu, Alerts },

    methods: {
        ...mapActions("alerts", ["addAlert"]),
        async enableNetwork() {
            try {
                await db.enableNetwork();
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Nepodařilo se zapnout síť.",
                    timeout: 5000
                });
            }
        },
        async disableNetwork() {
             try {
                await db.disableNetwork();
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Nepodařilo se vypnout síť.",
                    timeout: 5000
                });
            }
        },

        async enablePersistence() {
             try {
                 await db.enablePersistence();
            } catch (error) {
                this.addAlert({
                    type: "warning",
                    color: "orange",
                    message: "Podpora offline režimu není k dispozici. Zůstaňte online.",
                });
            }
        },

        async loadConfig() {
             await this.$store.dispatch("config/fetchConfig");
        }
    },

    async beforeMount() {
        window.addEventListener("online", this.enableNetwork);
        window.addEventListener("offline", this.disableNetwork);

        //buggy - maybe only emulators, but dont know
        //await this.enablePersistence();

        await this.loadConfig();
    },

    beforeDestroy() {
        window.removeEventListener("online", this.enableNetwork);
        window.removeEventListener("offline", this.disableNetwork);
    },    
});
