


































































































































































































import Vue from "vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { Order, OrderState } from "../models/order";
import { User } from "../models/user";
import CustomDialog from "./CustomDialog.vue";
import ConfirmationDialog from "./ConfirmationDialog.vue";
import OrderViewer from "./OrderViewer.vue";
import moment from "moment";
import firebase from "firebase/app";
import { db } from "../firebase";
import { DELETE_ORDER } from "../store/mutationTypes";
import Utils from "../utils";

interface State {
    headers: any[];
    selected: any[];
    searchTerm: string | null;
    currentOrder: any;
    sortBy: string;
    sortDesc: boolean;
    searchActive: boolean;
    viewDialog: boolean;
    deleteConfirmationDialog: boolean;
    saleUsers: any[];
    userId: string | null;
    orderExporting: boolean;
}

export default Vue.extend({
    name: "Orders",

    props: {
        type: String,
        itemsPerPage: Number,
    },

    components: {
        CustomDialog,
        ConfirmationDialog,
        OrderViewer,
    },

    data: (): State => {
        const headers = [
            { text: "Číslo", value: "number" },
            { text: "Datum", value: "date", sortable: false },
            {
                text: "Zákazník",
                value: "billingAddress.company",
                sortable: false,
            },
            { text: "Částka", value: "total.price.withVat", sortable: false },
            { text: "Stav", value: "state" },
            {
                text: "",
                value: "actions",
                filterable: false,
                sortable: false,
                align: "end",
            },
        ];
        return {
            headers: headers,
            selected: [],

            currentOrder: null,

            searchTerm: null,
            searchActive: false,

            sortBy: "number",
            sortDesc: true,

            viewDialog: false,
            deleteConfirmationDialog: false,

            saleUsers: [],
            userId: null,

            orderExporting: false,
        };
    },

    computed: {
        ...mapGetters("orders", ["canNext", "canPrev", "loading"]),
        ...mapState("orders", ["items"]),
        ...mapState("user", ["currentUser"]),
        ...mapGetters("config", ["apiKey"]),
        showSelect() {
            return (
                this.currentUser &&
                (this.currentUser.roles.indexOf("admin") != -1 ||
                    this.currentUser.roles.indexOf("invoice") != -1)
            );
        },
        canDeleteOrder() {
            return (
                this.currentUser &&
                (this.currentUser.roles.indexOf("admin") != -1 ||
                    this.currentUser.roles.indexOf("invoice") != -1)
            );
        },
    },

    watch: {
        currentUser: {
            immediate: true,
            async handler() {
                await this.setCurrentUserId();
            },
        },
        sortBy: {
            async handler(value: string) {
                await this.orderBy();
            },
        },
        sortDesc: {
            async handler(value: boolean) {
                await this.orderBy();
            },
        },
        searchTerm: {
            async handler() {
                await this.fetch();
            },
        },
        userId: {
            async handler() {
                await this.fetch();
            },
        },
        itemsPerPage: {
            async handler() {
                await this.fetch();
            },
        },
        viewDialog: {
            handler() {
                if (!this.viewDialog) {
                    this.currentOrder = null;
                }
            },
        },
    },

    methods: {
        ...mapActions("alerts", ["addAlert"]),

        searchInput(input: string) {
            this.searchTerm = input;
        },
        toggleSearch() {
            this.searchTerm = null;
            this.searchActive = !this.searchActive;
        },

        async setCurrentUserId() {
            try {
                if (this.currentUser) {
                    if (this.currentUser.roles.indexOf("admin") == -1 && this.currentUser.roles.indexOf("invoice") == -1) {
                        this.userId = this.currentUser.id;
                    } else {
                        await this.$store.dispatch(
                            "user/fetchUsersByRole",
                            "sales"
                        );
                        this.saleUsers.push(
                            {
                                value: null,
                                text: "Všichni",
                            },
                            ...this.$store.state.user.users.map(
                                (user: User) => ({
                                    value: user.id,
                                    text: user.name,
                                })
                            )
                        );
                        this.userId = this.saleUsers[0].value;
                    }
                    await this.fetch();
                }
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba načítání: " + error.message,
                });
            }
        },

        async goToPrevious() {
            try {
                await this.$store.dispatch("orders/goToPrevious");
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba načítání: " + error.message,
                });
            }
        },

        async goToNext() {
            try {
                await this.$store.dispatch("orders/goToNext");
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba načítání: " + error.message,
                });
            }
        },

        async orderBy() {
            try {
                await this.$store.dispatch("orders/orderBy", {
                    sortBy: this.sortBy,
                    sortDesc: this.sortDesc,
                });
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba načítání: " + error.message,
                });
            }
        },

        async fetch() {
            try {
                await this.$store.dispatch("orders/fetch", {
                    itemsPerPage: this.itemsPerPage,
                    filters: {
                        searchTerm: this.searchTerm,
                        userId: this.userId,
                    },
                });
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba načítání: " + error.message,
                });
            }
        },

        async deleteOrder(order: Order) {
            try {
                await db.runTransaction(
                    async (transaction: firebase.firestore.Transaction) => {
                        const doc = await transaction.get(
                            db.collection("orders").doc(order.id)
                        );
                        if (doc.exists) {
                            if (doc.data()!.state >= OrderState.PROCESSING) {
                                throw new Error(
                                    "objednávka je již zpracovávána nebo exportována"
                                );
                            }
                            transaction.delete(doc.ref);
                        } else {
                            throw new Error("objednávka byla smazána dříve");
                        }
                    }
                );
                this.$store.commit(`orders/${DELETE_ORDER}`, order);
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: `Smazání se nepodařilo: ${error.message}.`,
                });
            }
        },

        async downloadPdf() {
            try {
                this.orderExporting = true;
                await Utils.exportOrdersToPdf(
                    [
                        {
                            id: this.currentOrder!.id,
                            number: this.currentOrder!.number,
                        },
                    ],
                    "sale",
                    this.apiKey
                );
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba exportu: " + error.message,
                });
            } finally {
                this.orderExporting = false;
            }
        },

        openViewDialog(item: Order) {
            this.currentOrder = item;
            this.viewDialog = true;
        },

        openDeleteConfirmationDialog(item: Order) {
            this.currentOrder = item;
            this.deleteConfirmationDialog = true;
        },

        formatDate(date: Date) {
            return moment(date).format("DD. MM. YYYY HH:mm:ss");
        },    

        async exportSelected() {
            try {
                this.orderExporting = true;
                const unexported = this.selected
                        .filter((o) => null == o.number).map((o) => o.number);

                const result = await Utils.exportInvoicesToXml(
                    this.selected
                        .filter((o) => null != o.number)
                        .map((o) => o.number),
                    this.apiKey
                );

                unexported.push(...result.unexported);

                if (unexported.length > 0) {
                    this.addAlert({
                        type: "warning",
                        color: "orange",
                        message: `Některé objednávky nebyly v požadovaném stavu pro export.`,
                        timeout: 5000
                    });
                }
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba exportu: " + error.message,
                });
            } finally {
                this.orderExporting = false;
            }
        },
    },
});
