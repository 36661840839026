

















import Vue from "vue";
import { mapState, mapActions } from "vuex";

export default Vue.extend({
  name: "Alerts",

  computed: {
    ...mapState("alerts", ["items"]),
  },

  methods: {
      ...mapActions("alerts", ["removeAlert"]),      
  },
});
