import { Module } from "vuex";
import { db } from '../../firebase';
import firebase from 'firebase/app'
import { Order, OrderConverter, OrderState } from '../../models/order';
import {
    FETCH_ORDERS,
    UPDATE_ORDER,
    SET_ORDERS_METADATA,
} from '../mutationTypes'
import { CollectionModuleConfiguration, CollectionModuleFactory, MutationNames } from "../collectionModuleFactory";
import { OrderByDirection } from "@/models/orderBy";

const orderProcessingModule: Module<any, any> = CollectionModuleFactory.create<Order>(new CollectionModuleConfiguration({
    name: 'orderProcessing',
    collectionName: 'orders',
    firstOrderByName: 'number',
    firstOrderByDirection: OrderByDirection.DESC,
    mutationNames: new MutationNames({
        FETCH: FETCH_ORDERS,
        UPDATE_ITEM: UPDATE_ORDER,
        SET_METADATA: SET_ORDERS_METADATA
    }),
    queryFactory: (state: any, rootGetters: any): firebase.firestore.Query => {
        const searchTerm = state.metadata.filters.searchTerm;
        const showCompleted = state.metadata.filters.showCompleted;
        const userId = state.metadata.filters.userId;

        let query: any = db.collection('orders');

        query = query.where("state", ">=", OrderState.READY);

        if (false == showCompleted) {
            query = query.where("state", "<", OrderState.EXPORTED);
        }

        if (userId) {
            query = query.where('userId', '==', userId);
        }

        if (searchTerm) {
            query = query.where('keywords', 'array-contains', searchTerm.trim().toLowerCase());
        }

        if (state.metadata.orderBy.name != 'state') {
            query = query.orderBy("state", OrderByDirection.ASC);
        }

        query = query.orderBy(state.metadata.orderBy.name, state.metadata.orderBy.direction.toString());

        return query;
    },
    entityConverter: new OrderConverter(),
    filters: {
        searchTerm: null,
        userId: null,
        showCompleted: false
    }    
}));

export default orderProcessingModule;