
















































import Vue, { PropOptions } from "vue";
import { mapState, mapMutations } from "vuex";
import { OrderItem } from "../models/orderItem";

export default Vue.extend({
    name: "OrderItems",

    props: {
        items: Array,
        type: String,
        withoutHeader: Boolean,
        noDataText: String,
    },

    computed: {
        headers() {
            if (
                this.type == "readonly-quantity" ||
                this.type == "readonly-processed"
            ) {
                return [
                    { text: "Kód", value: "code" },
                    { text: "Název", value: "name" },
                    { text: "Cena/Mj", value: "unitPrice" },
                    {
                        text: "Množství",
                        value:
                            this.type == "readonly-quantity"
                                ? "quantity"
                                : "processed",
                        align: "right",
                    },
                ];
            } else {
                return [
                    { text: "Kód", value: "code" },
                    { text: "Název", value: "name" },
                    { text: "Cena/Mj", value: "unitPrice" },
                    {
                        text: "",
                        value: "actions",
                        align: "end",
                        width: "100px",
                    },
                ];
            }
        },
    },

    methods: {
        plus(item: OrderItem) {
            if (this.type == "processing") {
                item.processed += 1;
            } else {
                item.quantity += 1;
                item.processed += 1;
            }
            this.$emit("change");
        },
        minus(item: OrderItem) {
            if (this.type == "processing") {
                if (item.processed > 0) {
                    item.processed -= 1;
                }
            } else {
                if (item.quantity > 1) {
                    item.quantity -= 1;
                    item.processed -= 1;
                }
            }
            this.$emit("change");
        },
        remove(item: OrderItem) {
            this.items.splice(this.items.indexOf(item), 1);
            this.$emit("change");
        },
    },
});
