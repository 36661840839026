





































































































































import Vue from "vue";
import { Order, OrderBilling, OrderShipping } from "../models/order";
import AddressViewer from "./AddressViewer.vue";
import OrderItems from "./OrderItems.vue";
export default Vue.extend({
    name: "OrderWizardRecapitulation",

    props: {
        order: Order,
    },

    components: {
        AddressViewer,
        OrderItems,
    },

    computed: {
        shippingDisplay(): string {
            if (this.order) {
                switch (this.order.shipping) {
                    case OrderShipping.PPL:
                        return "PPL";
                    case OrderShipping.MJC:
                        return "MJC";
                }
            }
            return "";
        },

        billingDisplay(): string {
            if (this.order) {
                switch (this.order.billing) {
                    case OrderBilling.DELIVERY:
                        return "Dobírka";
                    case OrderBilling.BANK:
                        return "Převodem";
                    case OrderBilling.CASH:
                        return "Hotově OZ";
                }
            }
            return "";
        },
    },
});
