







































import Vue from "vue";
import { Address } from "../models/address";

export default Vue.extend({
    name: "AddressEditor",

    props: {
        address: {
            type: Address,
            required: true,
        },
    },

    data() {
        return {
            editableAddress: this.address,
        };
    },

    watch: {
        address: {
            handler(value) {
                this.editableAddress = value;
            },
        },
    },

    methods: {},
});
