import { EditableObject } from "./editableObject";

export class Address implements EditableObject<Address> {
    street!: string;
    streetExtend!: string | null;
    city!: string;
    zip!: string;
    country!: string;

    constructor(init: Partial<Address>) {
        Object.assign(this, init);
    }
    clone(): Address {
        return new Address({ ...this });
    }
    update(address: Address): void {
        this.street = address.street;
        this.streetExtend = address.streetExtend;
        this.city = address.city;
        this.zip = address.zip;
        this.country = address.country;
    }

}