






























import Vue from "vue";

export default Vue.extend({
    name: "ConfirmationDialog",

    model: {
        prop: 'show',
        event: 'change'
    },

    props: {
        label: String,
        show: Boolean,
        title: String,
        backButton: String,
        confirmButton: String
    },
});
