
import { Module } from "vuex";
import { db } from '../../firebase';
import {
    SET_APP_CONFIG
} from '../mutationTypes'
const configModule: Module<any, any> = {
    namespaced: true,
    state: {
        app: {
            appName: null,
            apiKey: null,
            vatRates: []
        },
    },
    getters: {
        appName(state) {
            return state.app.appName;
        },
        vatRates(state){
            return state.app.vatRates;
        },
        apiKey(state) {
            return state.app.apiKey;
        }
    },
    mutations: {
        [SET_APP_CONFIG](state, appConfig) {
            state.app = appConfig;
        }
    },
    actions: {
        async fetchConfig({ commit, dispatch }) {
            const doc = await db.collection('config').doc('app').get();
            if (false == doc.exists) {
                dispatch('alerts/addAlert', {
                    type: "error",
                    color: "red",
                    message: "Konfigurace není dostupná."
                }, { root: true })
            } else {
                commit(SET_APP_CONFIG, doc.data()?.config);
            }
        }
    }
}

export default configModule;