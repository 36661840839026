var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"disable-sort":"","disable-pagination":"","hide-default-footer":"","hide-default-header":_vm.withoutHeader,"no-data-text":_vm.noDataText || 'Začněte výběrem položek z ceníku'},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity)+"x ")]}},{key:"item.processed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.processed)+" ")]}},{key:"item.unitPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.unitPrice ? ((item.unitPrice) + ",-") : "0,-")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions-column"},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.plus(item)}}},[_vm._v(" mdi-plus ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.type == 'creating'),expression:"type == 'creating'"}]},[_vm._v(_vm._s(item.quantity))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.type == 'processing'),expression:"type == 'processing'"}]},[_vm._v(_vm._s(item.processed))]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.minus(item)}}},[_vm._v(" mdi-minus ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.type == 'processing'),expression:"type == 'processing'"}],class:item.quantity > item.processed
                        ? 'red--text'
                        : item.quantity == item.processed
                        ? 'green--text'
                        : 'orange--text'},[_vm._v("("+_vm._s(item.quantity)+")")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.type == 'creating'),expression:"type == 'creating'"}],attrs:{"small":""},on:{"click":function($event){return _vm.remove(item)}}},[_vm._v(" mdi-delete ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }