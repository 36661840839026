import { Address } from "./models/address";
import axios from 'axios';
import fileDownload from 'js-file-download';
import pdfjs from 'pdfjs';

export default class Utils {
    static deepCopyObject<T>(type: { new(...args: any[]): T }, object: T): T {
        const cp = object as { [key: string]: any };
        Object.keys(cp).forEach((prop: any) => {
            const value = cp[prop];

            if (value instanceof Address) {
                cp[prop] = this.deepCopyObject(Address, value);
            }
        })

        return new type(cp);
    }

    static commonRegularExpressions(name: string): RegExp {
        switch (name) {
            //eslint-disable-next-line
            case 'email': return /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4}[\s;]*)*$/;
            case 'phone': return /^(\+[0-9]{3})?[0-9\s;]+$/;
            case 'ico': return /^N?[0-9]{6,10}$/;
            case 'dic': return /^[A-Za-z]{2,3}[0-9]{6,10}$/;
            default: return /.*/;
        }
    }

    static roundPrice(price: number, decimalPlaces: number) {
        const coeficient = Math.pow(10, decimalPlaces);
        return Math.round((price + Number.EPSILON) * coeficient) / coeficient;
    }

    static computeVat(priceWithVat: number, vatRate: number) {
        const vat = priceWithVat * (vatRate / (100 + vatRate));
        return Utils.roundPrice(vat, 2);
    }

    static firstOrDefault<T>(array: Array<T>, predicate: (value: T) => boolean, def: T | null) {
        const filter = array.filter(predicate);
        if (filter.length > 0) {
            return filter[0];
        } else {
            return def || null;
        }
    }

    static async exportOrdersToPdf(identities: { id: string; number: string }[], templateId: string, apiKey: string) {
        const blobs = [];

        const results = await Promise.allSettled(identities.map((identity) => {
            return axios.get(`${process.env.VUE_APP_API_BASE_URL}/services-export/v1/order/${identity.id}/${templateId}`, {
                responseType: 'blob',
                headers: {
                    'ApiKey': apiKey
                }
            })
        }))

        for (let i = 0; i < results.length; i++) {
            const result = results[i];
            const orderInfo = identities[i];
            if(result.status == 'fulfilled') {
                if (results.length == 1) {
                    fileDownload(result.value.data, `objednavka-${orderInfo.number}.pdf`);
                } else {
                    blobs.push(result.value.data);
                }
            } 
            
        }
        if (blobs.length > 0) {
            const doc = new pdfjs.Document();
            for (let i = 0; i < blobs.length; i++) {
                const blob = blobs[i];
                const arrayBuffer = await blob.arrayBuffer();
                doc.addPagesOf(new pdfjs.ExternalDocument(arrayBuffer));
            }
            const buffer = await doc.asBuffer();
            fileDownload(buffer, `objednavky.pdf`);
        }
    }

    static async exportInvoicesToXml(orders: { number: string }[], apiKey: string): Promise<any> {
        const result = { unexported: [] };
        if(orders.length > 0) {
            const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/services-export/v1/invoices/${orders.join(',')}`, {            
                headers: {
                    'ApiKey': apiKey
                }
            });
            if(response.data.content) {
                fileDownload(Uint8Array.from(atob(response.data.content), c => c.charCodeAt(0)), `faktury.xml`);
            }            
            result.unexported = response.data.unexported
           
        } 
        return result;     
    }

    static async loadOrderPdfAsBase64(id: string, templateId: string, apiKey: string) {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/services-export/v1/order/${id}/${templateId}`, {
            responseType: 'blob',
            headers: {
                'ApiKey': apiKey
            }
        });
        const buffer = await response.data.arrayBuffer();
        return `data:application/pdf;base64,${btoa(new Uint8Array(buffer).reduce(function (data, byte) {
            return data + String.fromCharCode(byte);
        }, ''))}`;
    }
}