import { OrderItem } from './orderItem';
import { OrderPrice } from './orderPrice';
import { OrderTotal, OUT_OF_VAT_RATE, ZERO_VAT_RATE } from './orderTotal';
import { BillingAddress } from './billingAddress';
import { ShippingAddress } from './shippingAddress';
import { Document } from './document';
import { FirestoreConverter } from './firestoreConverter';
import firebase from 'firebase/app'
import { Address } from './address';
import { EditableObject } from './editableObject';
import Utils from '@/utils';
import moment from 'moment';

export enum OrderState {
    CREATING = 0,
    READY = 1,
    PROCESSING = 2,
    PROCESSED = 3,
    EXPORTED = 4
}

export enum OrderShipping {
    PPL = 0,
    MJC = 1,
}

export enum OrderBilling {
    DELIVERY = 0,
    BANK = 1,
    CASH = 2,
}

export const PPL_THRESHOLD = 1500;
export const PPL_THRESHOLD_2 = 1800;
const PPL_PRICE_WITH_VAT = 149;
const SHIPPING_VATRATE = 21;

export class Order extends Document implements EditableObject<Order> {
   
    static create(userId: string, contract: string, rates: number[], creatingTag: string, userDisplayName: string, notificationEmail: string) {
        const totalRates: {
            [rate: string]: OrderPrice;
        } = {
            [OUT_OF_VAT_RATE]: new OrderPrice({
                base: 0,
                vat: null,
                withVat: null
            }),
            [ZERO_VAT_RATE]: new OrderPrice({
                base: 0,
                vat: null,
                withVat: null
            })
        };
        const invoicableRates: {
            [rate: string]: OrderPrice;
        } = {
            [OUT_OF_VAT_RATE]: new OrderPrice({
                base: 0,
                vat: null,
                withVat: null
            }),
            [ZERO_VAT_RATE]: new OrderPrice({
                base: 0,
                vat: null,
                withVat: null
            })
        };
        for (let i = 0; i < rates.length; i++) {
            totalRates[rates[i]] = new OrderPrice({
                base: 0,
                vat: 0,
                withVat: 0
            });
            invoicableRates[rates[i]] = new OrderPrice({
                base: 0,
                vat: 0,
                withVat: 0
            })
        }

        return new Order({
            contract: contract,
            state: OrderState.CREATING,
            freeItems: [],
            priceableItems: [],
            total: new OrderTotal({
                rates: totalRates,
                price: new OrderPrice({
                    base: 0,
                    vat: 0,
                    withVat: 0
                })
            }),
            invoiceableTotal: new OrderTotal({
                rates: invoicableRates,
                price: new OrderPrice({
                    base: 0,
                    vat: 0,
                    withVat: 0
                })
            }),
            userId: userId,
            userDisplayName: userDisplayName,
            notificationEmail: notificationEmail,
            creatingTag: creatingTag,
            billing: OrderBilling.DELIVERY,            
            shipping: OrderShipping.PPL,
            shippingPrice: PPL_PRICE_WITH_VAT,
            invoiceableShippingPrice: PPL_PRICE_WITH_VAT,

            number: null,
            date: null,
            note: null,
            customerId: null,
            customerPhone: null,
            billingAddress: null,
            shippingAddressId: null,
            shippingAddress: null,
            
        })
    }
    number!: string | null;
    date!: Date | null;
    note!: string | null;
    creatingTag!: string | null;
    contract!: string;
    state!: OrderState;
    customerId!: string | null;
    customerPhone!: string | null;
    billingAddress!: BillingAddress | null;
    shippingAddressId!: string | null;
    shippingAddress!: ShippingAddress | null;
    billing!: OrderBilling;    
    shipping!: OrderShipping;
    shippingPrice!: number;
    invoiceableShippingPrice!: number;
    freeItems!: OrderItem[];
    priceableItems!: OrderItem[];
    total!: OrderTotal;
    invoiceableTotal!: OrderTotal;
    userId!: string;
    userDisplayName!: string;
    notificationEmail!: string;

    constructor(init: Partial<Order>) {
        super(init.id!);
        Object.assign(this, init);
    }
    clone(): Order {
        return new Order({
            id: this.id,
            number: this.number,
            date: this.date,
            note: this.note,
            creatingTag: this.creatingTag,
            contract: this.contract,
            state: this.state,
            customerId: this.customerId,
            customerPhone: this.customerPhone,
            billingAddress: this.billingAddress?.clone(),
            shippingAddressId: this.shippingAddressId,
            shippingAddress: this.shippingAddress?.clone(),
            billing: this.billing,
            shipping: this.shipping,
            shippingPrice: this.shippingPrice,
            invoiceableShippingPrice: this.invoiceableShippingPrice,
            freeItems: this.freeItems.map(item => item.clone()),
            priceableItems: this.priceableItems.map(item => item.clone()),
            total: this.total?.clone(),
            invoiceableTotal: this.invoiceableTotal?.clone(),
            userId: this.userId,       
            userDisplayName: this.userDisplayName,
            notificationEmail: this.notificationEmail
        })
    }
    update(order: Order): void {
        this.number = order.number;
        this.date = order.date;
        this.note = order.note;
        this.creatingTag = order.creatingTag;
        this.contract = order.contract;
        this.state = order.state;
        this.customerId = order.customerId;
        this.customerPhone = order.customerPhone;
        this.billingAddress = order.billingAddress;
        this.shippingAddressId = order.shippingAddressId;
        this.shippingAddress = order.shippingAddress;
        this.billing = order.billing;
        this.shipping = order.shipping;
        this.shippingPrice = order.shippingPrice;
        this.invoiceableShippingPrice = order.invoiceableShippingPrice;
        this.freeItems = order.freeItems;
        this.priceableItems = order.priceableItems;
        this.total = order.total;
        this.invoiceableTotal = order.invoiceableTotal;
        this.userId = order.userId;   
        this.userDisplayName = order.userDisplayName;  
        this.notificationEmail = order.notificationEmail;  
    }

    recomputePrice() {
        let totalAcc = 0;
        this.total.clear();
        this.priceableItems.forEach((item: OrderItem) => {
            const withVatTotal = Utils.roundPrice(item.unitPrice * item.quantity, 3);
            this.total.rates[item.vatRate!].withVat! += withVatTotal;
            totalAcc+= withVatTotal;
        });

        this.shippingPrice = 0;
        if(this.shipping == OrderShipping.PPL) {
            if(this.isTotalUnderPplThreshold(totalAcc)) {            
                this.shippingPrice = PPL_PRICE_WITH_VAT;
            }
        }    

        this.total.rates[SHIPPING_VATRATE].withVat! += this.shippingPrice;

        Object.keys(this.total.rates).forEach(key => {
            const rate = this.total.rates[key];
            rate.vat = Utils.computeVat(this.total.rates[key].withVat!, Number(key))
            rate.base = Utils.roundPrice(rate.withVat! - rate.vat, 2);

            this.total.price.withVat! += rate.withVat!;
            this.total.price.vat! += rate.vat!;
            this.total.price.base! += rate.base!;
        });   
                
        this.computeInvoicablePrice();
    }

    private isTotalUnderPplThreshold(total: number) {
        const now = moment.utc();
        if(now < moment.utc('2024-06-28T22:00:00Z')) {
            return total < PPL_THRESHOLD;
        } else {
            return total < PPL_THRESHOLD_2;
        }
    }

    private computeInvoicablePrice() {
        let totalAcc = 0;
        this.invoiceableTotal.clear();
        this.priceableItems.forEach((item: OrderItem) => {
            const withVatTotal = Utils.roundPrice(item.unitPrice * item.processed, 3);
            this.invoiceableTotal.rates[item.vatRate!].withVat! += withVatTotal;
            totalAcc+= withVatTotal;
        });

        this.invoiceableShippingPrice = 0;
        if(this.shipping == OrderShipping.PPL) {
            if(this.isTotalUnderPplThreshold(totalAcc)) {
                this.invoiceableShippingPrice = PPL_PRICE_WITH_VAT;
            }
        }   

        this.invoiceableTotal.rates[SHIPPING_VATRATE].withVat! += this.invoiceableShippingPrice          

        Object.keys(this.invoiceableTotal.rates).forEach(key => {
            const rate = this.invoiceableTotal.rates[key];
            rate.vat = Utils.computeVat(this.invoiceableTotal.rates[key].withVat!, Number(key))
            rate.base = Utils.roundPrice(rate.withVat! - rate.vat, 2);

            this.invoiceableTotal.price.withVat! += rate.withVat!;
            this.invoiceableTotal.price.vat! += rate.vat!;
            this.invoiceableTotal.price.base! += rate.base!;
        }); 
    }
}


export class OrderConverter implements FirestoreConverter<Order> {
    toFirestore(order: Order) {
        return {
            number: order.number,
            date: order.date ? firebase.firestore.Timestamp.fromDate(order.date) : null,
            note: order.note,
            creatingTag: order.creatingTag,
            contract: order.contract,
            state: order.state,
            customerId: order.customerId,
            customerPhone: order.customerPhone,
            billingAddress: order.billingAddress ? {
                company: order.billingAddress.company,
                subId: order.billingAddress.subId,
                vatId: order.billingAddress.vatId,
                address: {
                    street: order.billingAddress.address.street,
                    streetExtend: order.billingAddress.address.streetExtend,
                    city: order.billingAddress.address.city,
                    zip: order.billingAddress.address.zip,
                    country: order.billingAddress.address.country,
                }
            } : null,
            shippingAddressId: order.shippingAddressId,
            shippingAddress: order.shippingAddress ? {
                name: order.shippingAddress.name,
                address: {
                    street: order.shippingAddress.address.street,
                    streetExtend: order.shippingAddress.address.streetExtend,
                    city: order.shippingAddress.address.city,
                    zip: order.shippingAddress.address.zip,
                    country: order.shippingAddress.address.country,
                }
            } : null,
            billing: order.billing,
            shipping: order.shipping,
            shippingPrice: order.shippingPrice,
            invoiceableShippingPrice: order.invoiceableShippingPrice,
            freeItems: order.freeItems.map((item: OrderItem) => ({
                name: item.name,
                code: item.code,
                quantity: item.quantity,
                processed: item.processed,
                vatRate: item.vatRate,
                unitPrice: item.unitPrice,
            })),
            priceableItems: order.priceableItems.map((item: OrderItem) => ({
                name: item.name,
                code: item.code,
                quantity: item.quantity,
                processed: item.processed,
                vatRate: item.vatRate,
                unitPrice: item.unitPrice,
            })),
            total: {
                rates: Object.keys(order.total.rates).reduce<any>((rates: any, vatRateKey: string) => (
                    rates[vatRateKey] = {
                        base: order.total.rates[vatRateKey].base,
                        vat: order.total.rates[vatRateKey].vat,
                        withVat: order.total.rates[vatRateKey].withVat
                    },
                    rates
                ), {}),
                price: {
                    base: order.total.price.base,
                    vat: order.total.price.vat,
                    withVat: order.total.price.withVat
                }
            },
            invoiceableTotal: {
                rates: Object.keys(order.invoiceableTotal.rates).reduce<any>((rates: any, vatRateKey: string) => (
                    rates[vatRateKey] = {
                        base: order.invoiceableTotal.rates[vatRateKey].base,
                        vat: order.invoiceableTotal.rates[vatRateKey].vat,
                        withVat: order.invoiceableTotal.rates[vatRateKey].withVat
                    },
                    rates
                ), {}),
                price: {
                    base: order.invoiceableTotal.price.base,
                    vat: order.invoiceableTotal.price.vat,
                    withVat: order.invoiceableTotal.price.withVat
                }
            },
            userId: order.userId,
            userDisplayName: order.userDisplayName,
            notificationEmail: order.notificationEmail
        }
    }
    fromFirestore(snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>, options: any): Order {
        const data = snapshot.data(options);
        return new Order({
            id: snapshot.id,
            number: data?.number,
            date: data?.date ? data?.date.toDate() : null,
            note: data?.note,
            creatingTag: data?.creatingTag,
            contract: data?.contract,
            state: data?.state,
            customerId: data?.customerId,
            customerPhone: data?.customerPhone,
            billingAddress: data?.billingAddress ? new BillingAddress({
                company: data?.billingAddress.company,
                subId: data?.billingAddress.subId,
                vatId: data?.billingAddress.vatId,
                address: new Address(data?.billingAddress.address)
            }) : null,
            shippingAddressId: data?.shippingAddressId,
            shippingAddress: data?.shippingAddress ? new ShippingAddress({
                name: data?.shippingAddress.name,
                address: new Address(data?.shippingAddress.address)
            }) : null,
            billing: data?.billing,
            shipping: data?.shipping,
            shippingPrice: data?.shippingPrice,
            invoiceableShippingPrice: data?.invoiceableShippingPrice,
            freeItems: data?.freeItems.map((item: any) => new OrderItem(item)),
            priceableItems: data?.priceableItems.map((item: any) => new OrderItem(item)),
            total: new OrderTotal({
                rates: Object.keys(data?.total.rates).reduce<any>((rates: any, vatRateKey: string) => (
                    rates[vatRateKey] = new OrderPrice({
                        base: data?.total.rates[vatRateKey].base,
                        vat: data?.total.rates[vatRateKey].vat,
                        withVat: data?.total.rates[vatRateKey].withVat
                    }),
                    rates
                ), {}),
                price: new OrderPrice(data?.total.price)
            }),
            invoiceableTotal: new OrderTotal({
                rates: Object.keys(data?.invoiceableTotal.rates).reduce<any>((rates: any, vatRateKey: string) => (
                    rates[vatRateKey] = new OrderPrice({
                        base: data?.invoiceableTotal.rates[vatRateKey].base,
                        vat: data?.invoiceableTotal.rates[vatRateKey].vat,
                        withVat: data?.invoiceableTotal.rates[vatRateKey].withVat
                    }),
                    rates
                ), {}),
                price: new OrderPrice(data?.invoiceableTotal.price)
            }),
            userId: data?.userId,
            userDisplayName: data?.userDisplayName,
            notificationEmail: data?.notificationEmail            
        });
    }
}