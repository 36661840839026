















































import Vue from "vue";
import ConfirmationDialog from "./ConfirmationDialog.vue";
export default Vue.extend({
    name: "CustomDialog",

    components: {
        ConfirmationDialog,
    },

    model: {
        prop: "show",
        event: "showChange",
    },

    props: {
        title: String,
        nested: {
            type: Boolean,
            default: false,
        },
        show: Boolean,
        shouldPromptClosing: Function
    },

    data() {
        return {
            showCancelConfirmationDialog: false
        };
    },

    methods: {
         close() {
            if (this.shouldPromptClosing && this.shouldPromptClosing()) {
                this.showCancelConfirmationDialog = true;
            } else {
                this.innerCancelChanges();
            }
        },

        innerCancelChanges() {
            this.$emit("showChange", false);
        },
    },
});
