import { User, UserConverter } from "@/models/user";
import { Module } from "vuex";
import { auth, db } from '../../firebase';
import router from '../../router/index';
import {
    LOGIN_USER,
    LOGOUT_USER,
    FETCH_USERS
} from '../mutationTypes';

interface State {
    currentUser: User | null;
    users: User[];
}

const COLLECTION_NAME = 'users';

const userModule: Module<State, any> = {
    namespaced: true,
    state: {
        currentUser: null,
        users: []
    },
    getters: {
        isAuthenticated(state) {
            return null != state.currentUser;
        },
        currentUserId(state) {
            return null != state.currentUser ? state.currentUser.id : null;
        },
        currentUserDisplayName(state) {
            return null != state.currentUser ? state.currentUser.name : null;
        },
        currentUserEmail(state) {
            return null != state.currentUser ? state.currentUser.email : null;
        },
        currentUserOptions(state) {
            return null != state.currentUser ? state.currentUser.config : null;
        }
    },
    mutations: {
        [LOGIN_USER](state, user) {
            state.currentUser = user;
        },
        [LOGOUT_USER](state) {
            state.currentUser = null;
        },
        [FETCH_USERS](state, users) {
            state.users = users
        }
    },
    actions: {
        async login({ dispatch }, input) {
            const { user } = await auth.signInWithEmailAndPassword(input.username, input.password);

            await dispatch('fetchUser', user);
            router.push('/')
        },
        
        async fetchUser({ commit }, user) {
            const doc = await db.collection(COLLECTION_NAME).doc(user.uid).withConverter(new UserConverter()).get();
            commit(LOGIN_USER, doc.data());
        },

        async fetchUsersByRole({ commit }, role) {
            const snapshot = await db.collection(COLLECTION_NAME)
                .where('roles', 'array-contains', role)
                .orderBy('name')
                .withConverter(new UserConverter())
                .get();
            const users = Array<User>();
            snapshot.forEach((doc) => {
                users.push(doc.data());
            });

            commit(FETCH_USERS, users);
        },

        async logout({ commit }) {
            await auth.signOut();

            commit(LOGOUT_USER);

            router.push('/login')
        }
    }
}

export default userModule;