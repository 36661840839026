import { EditableObject } from "./editableObject";
import { Order } from "./order";

export class OrderPrice implements EditableObject<OrderPrice> {    
    base!: number ;
    vat!: number | null;
    withVat!: number | null;

    constructor(init: Partial<OrderPrice>) {
        Object.assign(this, init);
    }
    clone(): OrderPrice {
        return new OrderPrice({
            base: this.base,
            vat: this.vat,
            withVat: this.withVat
        })
    }
    update(orderPrice: OrderPrice): void {
        this.base = orderPrice.base;
        this.vat = orderPrice.vat;
        this.withVat = orderPrice.withVat;
    }
    clear() {
        this.base = this.vat = this.withVat = 0;
    }
}