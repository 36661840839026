import { Module } from "vuex";
import { db } from '../../firebase';
import firebase from 'firebase/app'
import { Product, ProductConverter } from '../../models/product';
import {
    FETCH_PRODUCTS,
    SET_PRODUCTS_METADATA,
} from '../mutationTypes'
import { CollectionModuleConfiguration, CollectionModuleFactory, MutationNames } from "../collectionModuleFactory";
import { remove } from 'remove-accents';

const productsModule: Module<any, any> = CollectionModuleFactory.create<Product>(new CollectionModuleConfiguration({
    name: 'products',
    collectionName: 'products',
    firstOrderByName: 'code',
    firstOrderByDirection: null,
    mutationNames: new MutationNames({
        ADD_ITEM: 'not-used',
        REMOVE_ITEM: 'not-used',
        FETCH: FETCH_PRODUCTS,
        SET_METADATA: SET_PRODUCTS_METADATA
    }),
    queryFactory: (state: any): firebase.firestore.Query => {
        const searchTerm = state.metadata.filters.searchTerm;
        
        let query: any = db.collection('products');
    
        if (searchTerm) {
            const preparedSearchTerm = remove(searchTerm.replace(/\s/g, '').toLowerCase())
            query = query.where('keywords', 'array-contains', preparedSearchTerm);
        }
    
        query = query.orderBy(state.metadata.orderBy.name, state.metadata.orderBy.direction.toString());
    
        return query;
    },
    entityConverter: new ProductConverter(),
    filters: {
        searchTerm: null
    }  
}));

export default productsModule;