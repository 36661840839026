import { Address } from './address';
import { EditableObject } from './editableObject';

export class BillingAddress implements EditableObject<BillingAddress> {
    company!: string;
    subId!: string | null;
    vatId!: string | null;
    address!: Address;

    constructor(init: Partial<BillingAddress>) {
        Object.assign(this, init);
    }
    clone(): BillingAddress {
        return new BillingAddress({
            company: this.company,
            subId: this.subId,
            vatId: this.vatId,
            address: this.address.clone()
        })
    }
    update(billingAddress: BillingAddress): void {
        this.company = billingAddress.company;
        this.subId = billingAddress.subId;
        this.vatId = billingAddress.vatId;
        this.address = billingAddress.address;
    }
}