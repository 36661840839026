import { Address } from './address';
import { EditableObject } from './editableObject';
import { v4 as uuidv4 } from 'uuid';

export class ShippingAddress implements EditableObject<ShippingAddress> {
    static create() {
        return new ShippingAddress({
            id: uuidv4(),
            name: '',
            address: new Address({
                street: "",
                streetExtend: null,
                city: "",
                zip: "",
                country: "Česká republika",
            }),
        })
    }

    id!: string;
    name!: string;
    address!: Address;

    constructor(init: Partial<ShippingAddress>) {
        Object.assign(this, init);
    }
    clone(): ShippingAddress {
        return new ShippingAddress({
            id: this.id,
            name: this.name,
            address: this.address.clone()
        });
    }
    update(shippingAddress: ShippingAddress): void {
        this.name = shippingAddress.name;
        this.address = shippingAddress.address;
    }
}