
import { Module } from "vuex";
import {
    ADD_ALERT,
    REMOVE_ALERT
} from '../mutationTypes'
import { v4 as uuidv4 } from 'uuid'; 
const alertsModule: Module<any, any> = {
    namespaced: true,
    state: {
        items: [],
    },
    mutations: {
        [ADD_ALERT](state, alert) {            
            state.items.push(alert);
        },
        [REMOVE_ALERT](state, id) {
            const index = state.items.findIndex((item: any) => item.id == id);
            if(index != -1) {
                state.items.splice(index, 1)
            }
            
        }
    },
    actions: {
        addAlert({ commit, state }, alert) {
            alert.id = uuidv4();
            commit(ADD_ALERT, alert);
            if (alert.timeout) {
                setTimeout(() => {                    
                    commit(REMOVE_ALERT, alert.id);
                }, alert.timeout);
            }
        },
        removeAlert({commit}, alert) {
            commit(REMOVE_ALERT, alert.id);
        }
    }
}

export default alertsModule;