
















































































































































































































































































































































































































































































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import Products from "./Products.vue";
import OrderItems from "./OrderItems.vue";
import CustomDialog from "./CustomDialog.vue";
import ConfirmationDialog from "./ConfirmationDialog.vue";
import CustomerSelector from "./CustomerSelector.vue";
import OrderWizardRecapitulation from "./OrderWizardRecapitulation.vue";
import { Product } from "../models/product";
import { ContextCommand } from "../models/contextCommand";
import { OrderItem } from "../models/orderItem";
import {
    Order,
    OrderBilling,
    OrderConverter,
    OrderShipping,
    OrderState,
    PPL_THRESHOLD,
    PPL_THRESHOLD_2,
} from "../models/order";
import moment from "moment";
import { Customer } from "../models/customer";
import { ShippingAddress } from "../models/shippingAddress";
import firebase from "firebase/app";
import { db } from "../firebase";
import { EditableObject } from "../models/editableObject";
import { BillingAddress } from "../models/billingAddress";
import { OrderByDirection } from "../models/orderBy";
import Utils from "../utils";

interface State {
    currentOrder: Order | null;
    currentStep: number;
    maxStep: number;
    window: any | null;
    ordersDialog: boolean;
    ordersHeader: any[];
    orderDeleteConfirmationDialog: boolean;
    orderForDeletion: Order | null;
    orders: Order[];
    loading: boolean;
    orderConverter: OrderConverter;
    dirty: boolean;
}

export default Vue.extend({
    name: "OrderWizard",

    components: {
        Products,
        OrderItems,
        CustomDialog,
        ConfirmationDialog,
        CustomerSelector,
        OrderWizardRecapitulation,
    },

    data: (): State => {
        return {
            currentOrder: null,
            currentStep: 1,
            maxStep: 1,
            window: {
                width: 0,
                height: 0,
            },
            ordersDialog: false,
            ordersHeader: [
                { text: "Označení", value: "creatingTag" },
                {
                    text: "",
                    value: "actions",
                    filterable: false,
                    sortable: false,
                    align: "end",
                },
            ],
            orderDeleteConfirmationDialog: false,
            orderForDeletion: null,
            orders: [],
            loading: false,
            orderConverter: new OrderConverter(),
            dirty: false,
        };
    },

    computed: {
        ...mapGetters("user", [
            "currentUserId",
            "currentUserOptions",
            "currentUserDisplayName",
            "currentUserEmail"
        ]),
        ...mapGetters("config", { configVatRates: "vatRates" }),

        productsPerPage(): number {
            const cols = 6;
            const otherHeigths = 48 + 48 + 48 + 48 + 60;
            const tileHeightApproxPlusReserve = 120;
            return (
                Math.floor(
                    (this.window.height - otherHeigths) /
                        tileHeightApproxPlusReserve
                ) * cols
            );
        },

        itemsContainerHeight(): number {
            const otherHeigths = 48 + 48 + 48 + 4 + 48 + 60; //topbar + paddings + progress + stepper header + inner toolbar + stepper footer
            return Math.floor(this.window.height - otherHeigths);
        },

        submitStepDisabled(): boolean {
            return (
                (this.currentStep == 2 &&
                    this.currentOrder!.freeItems.length == 0 &&
                    this.currentOrder!.priceableItems.length == 0) ||
                (this.currentStep == 3 &&
                    null == this.currentOrder!.customerId) ||
                (this.currentStep == 4 &&
                    (null == this.currentOrder!.shipping ||
                        null == this.currentOrder!.billing))
            );
        },

        shippingList(): any[] {
            return [
                {
                    label: "PPL",
                    value: OrderShipping.PPL,
                },
                {
                    label: "MJC",
                    value: OrderShipping.MJC,
                },
            ];
        },

        billingList(): any[] {
            return this.currentOrder!.shipping == OrderShipping.PPL ? [
                {
                    label: "Dobírka",
                    value: OrderBilling.DELIVERY,
                },
                {
                    label: "Převodem",
                    value: OrderBilling.BANK,
                },
                {
                    label: "Hotově OZ",
                    value: OrderBilling.CASH,
                }
            ] : [
                {
                    label: "Převodem",
                    value: OrderBilling.BANK,
                },
                {
                    label: "Hotově OZ",
                    value: OrderBilling.CASH,
                }
            ];
        },

        orderTotalWithoutShipping(): number {
            return this.currentOrder && this.currentOrder.total.price.withVat! > 0 ? Utils.roundPrice(this.currentOrder.total.price.withVat! - this.currentOrder.shippingPrice, 2) : 0;
        },

        freeShippingDiffPrice(): number {
            function getPplThreshold() {
                const now = moment.utc();
                if (now < moment.utc("2024-06-28T22:00:00Z")) {
                    return PPL_THRESHOLD;
                } else {
                    return PPL_THRESHOLD_2;
                }
            }
            return this.currentOrder && this.currentOrder.shippingPrice > 0
                ? Utils.roundPrice(getPplThreshold() - this.orderTotalWithoutShipping, 2)
                : 0;
        }
    },

    watch: {
        currentUserId: {
            immediate: true,
            async handler() {
                await this.initialize();
            },
        },
        configVatRates: {
            async handler() {
                await this.initialize();
            },
        },
        currentStep: {
            async handler() {
                await this.updateCurrentOrder();
            },
        },
    },

    methods: {
        ...mapActions("alerts", ["addAlert"]),

        async initialize() {
            try {
                this.loading = true;

                if (this.currentUserId && this.configVatRates.length > 0) {
                    const snapshot = await db
                        .collection("orders")
                        .where("state", "==", OrderState.CREATING)
                        .where("userId", "==", this.currentUserId)
                        .withConverter(this.orderConverter)
                        .orderBy("creatingTag", OrderByDirection.ASC)
                        .limit(100) //pojistka
                        .get();
                    snapshot.forEach(
                        (
                            doc: firebase.firestore.QueryDocumentSnapshot<Order>
                        ) => {
                            this.orders.push(doc.data());
                        }
                    );
                    if (this.orders.length == 0) {
                        await this.createNewOrder();
                    }

                    this.currentOrder = this.orders[0];
                }
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba načítání: " + error.message,
                });
            } finally {
                this.loading = false;
            }
        },

        updateBillingAddress(customer: Customer | null) {
            if (customer) {
                this.currentOrder!.customerId = customer.id;
                this.currentOrder!.customerPhone = customer.phone;
                if (this.currentOrder!.billingAddress) {
                    (this.currentOrder!
                        .billingAddress as EditableObject<BillingAddress>).update(
                        customer.billingAddress
                    );
                } else {
                    this.currentOrder!.billingAddress = customer.billingAddress.clone();
                }
            } else {
                this.currentOrder!.customerId = null;
                this.currentOrder!.customerPhone = null;
                this.currentOrder!.billingAddress = null;
                this.updateShippingAddress(null);
            }

            this.dirty = true;
        },

        updateShippingAddress(shippingAddress: ShippingAddress | null) {
            if (shippingAddress) {
                this.currentOrder!.shippingAddressId = shippingAddress.id;
                if (this.currentOrder!.shippingAddress) {
                    (this.currentOrder!
                        .shippingAddress as EditableObject<ShippingAddress>).update(
                        shippingAddress
                    );
                } else {
                    this.currentOrder!.shippingAddress = shippingAddress.clone();
                }
            } else {
                this.currentOrder!.shippingAddressId = null;
                this.currentOrder!.shippingAddress = null;
            }

            this.dirty = true;
        },

        addPriceableItem(item: Product) {
            this.currentOrder!.priceableItems.push(
                OrderItem.create(item)
            );
            this.currentOrder!.recomputePrice();

            this.dirty = true;
        },

        priceableItemsChanged() {
            this.currentOrder!.recomputePrice();

            this.dirty = true;
        },

        addFreeItem(item: Product) {
            this.currentOrder!.freeItems.push(OrderItem.create(item));

            this.dirty = true;
        },

        updateShipping(shipping: OrderShipping) {            
            this.currentOrder!.shipping = shipping;
            this.currentOrder!.recomputePrice();

            if(shipping == OrderShipping.MJC && this.currentOrder!.billing == OrderBilling.DELIVERY) {
                this.updateBilling(OrderBilling.BANK);
            }
            this.dirty = true;
        },

        updateBilling(billing: OrderBilling) {
            this.currentOrder!.billing = billing;

            this.dirty = true;
        },

        updateNote(note: string) {
            this.currentOrder!.note = note;

            this.dirty = true;
        },

        async updateCreatingTag(input: string) {
            if (input != this.currentOrder!.creatingTag) {
                this.currentOrder!.creatingTag = input;
                await this.updateCurrentOrder();
            }
        },

        async createNewOrder() {
            try {
                this.loading = true;

                const newOrder: Order = Order.create(
                    this.currentUserId,
                    this.currentUserOptions.contract,
                    this.configVatRates,
                    `Objednávka ${moment().format("yyyy-MM-DD-HH-mm-ss")}`,
                    this.currentUserDisplayName,
                    this.currentUserEmail
                );

                const doc = await db
                    .collection("orders")
                    .withConverter(this.orderConverter)
                    .add(newOrder);

                newOrder.id = doc.id;
                this.orders.unshift(newOrder);

                if (!this.currentOrder) {
                    this.currentOrder = newOrder;
                }
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Vytvoření se nepodařilo: " + error.message,
                });
            } finally {
                this.loading = false;
            }
        },

        async updateCurrentOrder() {
            try {
                this.loading = true;

                if (this.currentOrder) {
                    const doc = db
                        .collection("orders")
                        .doc(this.currentOrder.id);
                    await doc
                        .withConverter(this.orderConverter)
                        .set(this.currentOrder, { merge: true });
                }
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Uložení se nepodařilo: " + error.message,
                });
            } finally {
                this.loading = false;
                this.dirty = false;
            }
        },

        openOrderDeleteConfirmationDialog(item: Order) {
            this.orderForDeletion = item;
            this.orderDeleteConfirmationDialog = true;
        },

        async deleteOrder(order: Order) {
            try {
                this.loading = true;

                const deleted = await db.runTransaction(
                    async (transaction: firebase.firestore.Transaction) => {
                        const doc = await transaction.get(
                            db.collection("orders").doc(order.id)
                        );
                        if (doc.exists) {
                            if (doc.data()!.state >= OrderState.PROCESSING) {
                                throw new Error(
                                    "objednávka je již zpracovávána nebo exportována"
                                );
                            }
                            transaction.delete(doc.ref);
                            return true;
                        } else {
                            throw new Error("objednávka byla smazána dříve");
                        }
                    }
                );
                if(deleted) {
                    this.orders.splice(this.orders.indexOf(order), 1);
                    this.currentOrder =
                        this.orders.length > 0 ? this.orders[0] : null;
                }
                
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Smazání se nepodařilo: " + error.message,
                });
            } finally {
                this.loading = false;
            }
        },

        async selectOrder(order: Order) {
            if (!this.currentOrder) {
                await this.updateCurrentOrder();
            }
            this.currentOrder = order;
            this.ordersDialog = false;
            this.currentStep = 1; //again save assigned order if previous step <> 1
            this.maxStep = 1;
        },

        submitStep() {
            this.currentStep++;

            if (this.currentStep > this.maxStep) {
                this.maxStep = this.currentStep;
            }
        },

        goBack() {
            this.currentStep--;
        },

        async submit() {
            try {
                this.loading = true;

                let newOrderNumber = "";
                await db.runTransaction(
                    async (transaction: firebase.firestore.Transaction) => {
                        const userDoc = await transaction.get(
                            db.collection("users").doc(this.currentUserId)
                        );
                        const config = userDoc.data()!.config;
                        newOrderNumber =
                            config.series +
                            config.nextNumber
                                .toString()
                                .padStart(
                                    config.numberLength - config.series.length,
                                    "0"
                                );
                        this.currentOrder!.number = newOrderNumber;
                        this.currentOrder!.date = moment().toDate();

                        config.nextNumber += 1;

                        transaction.set(
                            userDoc.ref,
                            {
                                config,
                            },
                            { merge: true }
                        );

                        transaction.set(
                            db.collection("orders").doc(this.currentOrder!.id),
                            {
                                number: this.currentOrder!.number,
                                date: this.currentOrder!.date,
                                note: this.currentOrder!.note,
                                state: OrderState.READY,
                            },
                            { merge: true }
                        );
                    }
                );

                this.orders.splice(this.orders.indexOf(this.currentOrder!), 1);
                if (this.orders.length == 0) {
                    await this.createNewOrder();
                }
                this.currentOrder = this.orders[0];
                this.currentStep = 1; //again save assigned order
                this.maxStep = 1;

                this.addAlert({
                    type: "success",
                    color: "green",
                    message: `Objednávka ${newOrderNumber} vytvořena`,
                    timeout: 3000,
                });
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message:
                        "Vytvoření objednávky se nepodařilo: " + error.message,
                });
            } finally {
                this.loading = false;
                this.dirty = false;
            }
        },

        handleResize(): any {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },

        openOrdersDialog(): any {
            this.ordersDialog = true;
        },

        preventDirtyNav(event: any): any {
            if (!this.dirty) {
                return;
            }

            event.preventDefault();
            event.returnValue = "";
        },
    },

    beforeMount() {
        window.addEventListener("beforeunload", this.preventDirtyNav);
    },

    mounted() {
        this.handleResize();

        this.$store.dispatch("setContextCommands", [
            new ContextCommand({
                name: "Vybrat objednávku",
                icon: "mdi-text-box-multiple-outline",
                handler: this.openOrdersDialog,
            }),
        ]);
    },    

    async beforeDestroy() {
        if(this.dirty) {
            await this.updateCurrentOrder();
        }
        window.removeEventListener("beforeunload", this.preventDirtyNav);
    },

    destroyed() {
        this.$store.dispatch("setContextCommands", []);
    },
});
