import { BillingAddress } from './billingAddress';
import { ShippingAddress } from './shippingAddress';
import { Document } from './document';
import { FirestoreConverter } from './firestoreConverter';
import { Address } from './address';
import firebase from 'firebase/app'
import { EditableObject } from './editableObject';

export class Customer extends Document implements EditableObject<Customer> {
    static create(userId: string) {
        return new Customer({
            name: '',
            email: '',
            phone: '',
            billingAddress: new BillingAddress({
                company: "",
                subId: null,
                vatId: null,
                address: new Address({
                    street: "",
                    streetExtend: null,
                    city: "",
                    zip: "",
                    country: "Česká republika",
                }),
            }),
            shippingAddresses: [],
            userId: userId,
        })
    }
    name!: string;
    email!: string;
    phone!: string;
    billingAddress!: BillingAddress;
    shippingAddresses!: ShippingAddress[];
    userId!: string;

    constructor(init: Partial<Customer>) {
        super(init.id!);
        Object.assign(this, init);
    }
    clone(): Customer {
        return new Customer({
            id: this.id,
            name: this.name,
            email: this.email,
            phone: this.phone,
            userId: this.userId,
            billingAddress: this.billingAddress.clone(),
            shippingAddresses: this.shippingAddresses.map(shippingAddress => shippingAddress.clone())
        })
    }
    update(customer: Customer): void {        
        this.name = customer.name;
        this.email = customer.email;
        this.phone = customer.phone;
        this.userId = customer.userId;
        this.billingAddress = customer.billingAddress;        
        this.shippingAddresses = customer.shippingAddresses;
    }
}


export class CustomerConverter implements FirestoreConverter<Customer> {
    toFirestore(customer: Customer) {   
        return {
            name: customer.name,
            email: customer.email,
            phone: customer.phone,
            billingAddress: {
                company: customer.billingAddress.company,
                subId: customer.billingAddress.subId,
                vatId: customer.billingAddress.vatId,
                address: {
                    street: customer.billingAddress.address.street,
                    streetExtend: customer.billingAddress.address.streetExtend,
                    city: customer.billingAddress.address.city,
                    zip: customer.billingAddress.address.zip,
                    country: customer.billingAddress.address.country,
                }
            },
            shippingAddresses: customer.shippingAddresses.map((item: ShippingAddress) => ({
                id: item.id,
                name: item.name,
                address: {
                    street: item.address.street,
                    streetExtend: item.address.streetExtend,
                    city: item.address.city,
                    zip: item.address.zip,
                    country: item.address.country,
                }
            })),
            userId: customer.userId
        }
    }
    fromFirestore(snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>, options: any): Customer {        
        const data = snapshot.data(options);
        return new Customer({
            id: snapshot.id,
            name: data?.name,
            email: data?.email,
            phone: data?.phone,
            billingAddress: new BillingAddress({
                company: data?.billingAddress.company,
                subId: data?.billingAddress.subId,
                vatId: data?.billingAddress.vatId,
                address: new Address(data?.billingAddress.address)
            }),
            shippingAddresses: data?.shippingAddresses.map((item: any) => new ShippingAddress({
                id: item.id,
                name: item.name,
                address: new Address(item.address)
            })),
            userId: data?.userId
        });
    }
}