
















































import Vue from "vue";
import { mapState, mapGetters } from "vuex";

export default Vue.extend({
    name: "Navigation",

    computed: {
        ...mapState(["navOpen"]),
        ...mapGetters("config", ["appName"]),
        ...mapState("user", ["currentUser"]),

        links() {
            const links = [];
            if (this.currentUser) {
                if (
                    this.currentUser.roles.indexOf("sales") != -1 ||
                    this.currentUser.roles.indexOf("admin") != -1 ||
                    this.currentUser.roles.indexOf("invoice") != -1
                ) {
                    links.push({
                        title: "Klienti",
                        link: "/customers",
                        icon: "mdi-account-multiple",
                    });
                }

                if (
                    this.currentUser.roles.indexOf("sales") != -1 ||
                    this.currentUser.roles.indexOf("admin") != -1
                ) {
                    links.push({
                        title: "Objednávky",
                        link: "/orders",
                        icon: "mdi-file-document-multiple-outline",
                    });
                }

                 if (
                    this.currentUser.roles.indexOf("admin") != -1 ||
                    this.currentUser.roles.indexOf("invoice") != -1
                ) {
                    links.push({
                        title: "Vychystávání",
                        link: "/orders-processing",
                        icon: "mdi-text-box-check-outline",
                    });
                }

                links.push({
                    title: "Zboží",
                    link: "/products",
                    icon: "mdi-cube-outline",
                });
            }
            return links;
        },
    },

    methods: {
        ensureSideMenuClose(state: boolean) {
            if (!state) {
                this.$store.dispatch("closeNav");
            }
        },
        logout() {
            this.$store.dispatch("user/logout");
        },
    },
});
