var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!!_vm.selectedCustomer)?_c('v-card',{attrs:{"elevation":"2","tile":""}},[_c('v-card-title',[_c('div',{staticClass:"mb-2 d-flex align-center"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),_c('span',{staticClass:"title font-weight-bold"},[_vm._v("Vybraný zákazník")])],1)]),_c('v-card-text',[_c('h4',{staticClass:"mb-2"},[_vm._v("Fakturační adresa")]),_c('div',[_c('label',[_vm._v("Název:")]),_c('span',[_vm._v(" "+_vm._s(_vm.selectedCustomer.billingAddress.company))])]),_c('div',[_c('label',[_vm._v("IČO:")]),_c('span',[_vm._v(" "+_vm._s(_vm.selectedCustomer.billingAddress.subId))])]),_c('div',[_c('label',[_vm._v("Telefon:")]),_c('span',[_vm._v(" "+_vm._s(_vm.selectedCustomer.phone))])]),_c('AddressViewer',{attrs:{"type":"oneline","address":_vm.selectedCustomer.billingAddress.address}}),_c('h4',{staticClass:"mt-5 mb-2"},[_vm._v("Dodací adresa")]),_c('Selector',{attrs:{"staticItems":_vm.shippingAddresses,"selectedItem":_vm.selectedShippingAddress,"label":""},on:{"select":function (shippingAddress) {
                        this$1.selectedShippingAddress = shippingAddress;
                    }}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){_vm.selectedCustomer = null}}},[_vm._v("mdi-magnify")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.editCustomer()}}},[_vm._v("mdi-pencil")])],1)],1)],1):_c('v-toolbar',{staticClass:"d-flex justify-center",attrs:{"flat":""}},[_c('Selector',{attrs:{"query":_vm.searchCustomers,"textProvider":_vm.customerTextProvider,"selectedItem":_vm.selectedCustomer,"label":"Vyberte zákazníka","placeholder":"Zadejte IČO nebo název","prependIcon":"mdi-account"},on:{"select":function (customer) {
                    this$1.selectedCustomer = customer;
                }}}),_c('span',{staticClass:"mx-10"},[_vm._v("nebo")]),_c('v-btn',{staticClass:"create-customer-btn",attrs:{"color":"primary","tile":"","large":""},on:{"click":_vm.createCustomer}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Vytvořte nového zákazníka ")],1)],1),(_vm.editableCustomer)?_c('DetailDialog',{attrs:{"saveHandler":_vm.saveCustomerHandler,"title":_vm.editableCustomer.name ? _vm.editableCustomer.name : 'Nový zákazník',"originalItem":_vm.editableCustomer},scopedSlots:_vm._u([{key:"content",fn:function(ref){
                var item = ref.item;
return [_c('CustomerEditor',{attrs:{"customer":item}})]}}],null,false,1460163110),model:{value:(_vm.editableCustomerDetailDialog),callback:function ($$v) {_vm.editableCustomerDetailDialog=$$v},expression:"editableCustomerDetailDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }