import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import { auth } from '../firebase'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/customers',
    name: 'Zákazníci',
    component: () => import(/* webpackChunkName: "customers" */ '../views/CustomersView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders',
    name: 'Objednávky',
    component: () => import(/* webpackChunkName: "orders" */ '../views/OrdersView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders-processing',
    name: 'Sklad',
    component: () => import(/* webpackChunkName: "orders-processing" */ '../views/OrderProcessView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/products',
    name: 'Zboží',
    component: () => import(/* webpackChunkName: "products" */ '../views/ProductsView.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
