var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.editableCustomer.id)?_c('v-toolbar',[_c('v-text-field',{attrs:{"value":_vm.subIdForSearch,"placeholder":"Zadejte IČO pro vyhledání ","hide-details":"","single-line":"","loading":_vm.aresLoading},on:{"change":_vm.subIdForSearchInput}}),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1):_vm._e(),(!_vm.editableCustomer.id)?_c('v-alert',{staticClass:"mt-5",attrs:{"border":"bottom","color":"blue","icon":"mdi-lightbulb-on-outline","text":"","type":"info"}},[_vm._v(" Informace o klientech zadávejte včetně diakritiky. ")]):_vm._e(),_c('v-card',{staticClass:"my-2 px-6",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Kontaktní údaje")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Název","rules":[function (v) { return !!v || 'Název je povinný'; }]},model:{value:(_vm.editableCustomer.name),callback:function ($$v) {_vm.$set(_vm.editableCustomer, "name", $$v)},expression:"editableCustomer.name"}}),_c('v-text-field',{attrs:{"label":"E-mail","rules":[
                    function (v) { return _vm.regularExpressions.email.test(v) ||
                        'Neplatný email, jako oddělovač lze použít středník'; } ]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"link":"","text":"","href":'mailto:' + _vm.editableCustomer.email}},[_c('v-icon',[_vm._v(" mdi-email ")])],1)]},proxy:true}]),model:{value:(_vm.editableCustomer.email),callback:function ($$v) {_vm.$set(_vm.editableCustomer, "email", $$v)},expression:"editableCustomer.email"}}),_c('v-text-field',{attrs:{"label":"Telefon","rules":[
                    function (v) {
                        if (!v || v == '') {
                            return 'Telefon je povinný';
                        } else if (
                            false == _vm.regularExpressions.phone.test(v)
                        ) {
                            return 'Neplatný telefon, jako oddělovač lze použít středník';
                        } else {
                            return true;
                        }
                    } ]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"link":"","text":"","disabled":!_vm.editableCustomer.phone || '' == _vm.editableCustomer.phone,"href":'tel:' + _vm.editableCustomer.phone}},[_c('v-icon',[_vm._v(" mdi-phone ")])],1)]},proxy:true}]),model:{value:(_vm.editableCustomer.phone),callback:function ($$v) {_vm.$set(_vm.editableCustomer, "phone", $$v)},expression:"editableCustomer.phone"}})],1)],1),_c('v-card',{staticClass:"my-2 px-6",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Fakturační údaje")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Název firmy","maxLength":"50","rules":[function (v) { return !!v || 'Název firmy je povinný'; }]},model:{value:(_vm.editableCustomer.billingAddress.company),callback:function ($$v) {_vm.$set(_vm.editableCustomer.billingAddress, "company", $$v)},expression:"editableCustomer.billingAddress.company"}}),_c('v-text-field',{attrs:{"label":"IČO","rules":[
                    function (v) { return !!v || 'IČO je povinné'; },
                    function (v) { return _vm.regularExpressions.ico.test(v) || 'Neplatné IČO'; } ]},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.editableCustomer.id)?_c('v-btn',{attrs:{"link":"","text":"","loading":_vm.aresLoading,"disabled":_vm.aresLoading},on:{"click":function($event){return _vm.loadCustomerFromAres(
                                _vm.editableCustomer.billingAddress.subId
                            )}}},[_c('v-icon',[_vm._v(" mdi-refresh ")])],1):_vm._e()]},proxy:true}]),model:{value:(_vm.editableCustomer.billingAddress.subId),callback:function ($$v) {_vm.$set(_vm.editableCustomer.billingAddress, "subId", $$v)},expression:"editableCustomer.billingAddress.subId"}}),_c('v-text-field',{attrs:{"label":"DIČ","rules":[
                    function (v) { return !v ||
                        v == '' ||
                        _vm.regularExpressions.dic.test(v) ||
                        'Neplatný DIČ'; } ]},model:{value:(_vm.editableCustomer.billingAddress.vatId),callback:function ($$v) {_vm.$set(_vm.editableCustomer.billingAddress, "vatId", $$v)},expression:"editableCustomer.billingAddress.vatId"}}),_c('AddressEditor',{attrs:{"address":_vm.editableCustomer.billingAddress.address}})],1)],1),_c('v-card',{staticClass:"my-2 px-6",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Dodací údaje")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.shippingAddressesHeader,"items":_vm.editableCustomer.shippingAddresses,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dense":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"secondary"},on:{"click":_vm.addShippingAddress}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"item.address",fn:function(ref){
                var item = ref.item;
return [_c('AddressViewer',{attrs:{"type":"oneline","address":item.address}})]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openDetailDialog(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openDeleteConfirmationDialog(item)}}},[_vm._v(" mdi-delete ")])]}}])}),(_vm.currentShippingAddress)?_c('DetailDialog',{attrs:{"saveHandler":_vm.saveShippingAddressHandler,"title":_vm.currentShippingAddress.name,"originalItem":_vm.currentShippingAddress,"nested":true},scopedSlots:_vm._u([{key:"content",fn:function(ref){
                var item = ref.item;
return [_c('div',[_c('v-card',{staticClass:"my-2 px-6",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Kontaktní údaje")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Název","maxLength":"50","rules":[
                                        function (v) { return !!v || 'Název je povinný'; } ]},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1)],1),_c('v-card',{staticClass:"my-2 px-6",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Adresa")]),_c('v-card-text',[_c('AddressEditor',{attrs:{"address":item.address}})],1)],1)],1)]}}],null,false,566863808),model:{value:(_vm.detailDialog),callback:function ($$v) {_vm.detailDialog=$$v},expression:"detailDialog"}}):_vm._e(),(_vm.currentShippingAddress)?_c('ConfirmationDialog',{attrs:{"title":'Potvrzení smazání',"backButton":'Zavřít',"confirmButton":'Smazat'},on:{"confirm":function($event){return _vm.deleteShippingAddress(_vm.currentShippingAddress)}},model:{value:(_vm.deleteConfirmationDialog),callback:function ($$v) {_vm.deleteConfirmationDialog=$$v},expression:"deleteConfirmationDialog"}},[_c('div',[_c('p',{staticClass:"font-weight-regular py-2 my-0"},[_vm._v(" Opravdu chcete smazat tuto dodací adresu? ")]),_c('p',{staticClass:"font-weight-black py-2 my-0"},[_vm._v(" "+_vm._s(_vm.currentShippingAddress.name)+" ")])])]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }