


































































import Vue from "vue";
import { EditableObject } from "../models/editableObject";
import ConfirmationDialog from "./ConfirmationDialog.vue";
export default Vue.extend({
    name: "DetailDialog",

    components: {
        ConfirmationDialog,
    },

    model: {
        prop: "show",
        event: "showChange",
    },

    props: {
        title: String,
        originalItem: Object,
        nested: {
            type: Boolean,
            default: false,
        },
        show: Boolean,
        saveHandler: Function,
    },

    data() {
        return {
            formValid: true,
            showValidationMessage: false,
            validationMessage: null as string | null,
            showCancelConfirmationDialog: false,
            dirty: false,
            saving: false,
            item: null as any,
        };
    },

    computed: {
        detailForm: {
            get(): Vue & {
                validate: () => boolean;
                resetValidation: () => void;
            } {
                return this.$refs.detailForm as Vue & {
                    validate: () => boolean;
                    resetValidation: () => void;
                };
            },
        },
    },

    watch: {
        show: {
            immediate: true,
            handler(_, oldValue) {
                const opening = !oldValue;
                if (opening) {
                    this.item = (this
                        .originalItem as EditableObject<Document>).clone();
                    this.$nextTick(() => {
                        this.dirty = false;
                        this.formValid = true;
                        this.showValidationMessage = false;
                        this.validationMessage = null;
                        if (this.detailForm) {
                            this.detailForm.resetValidation();
                        }
                    });
                }
            },
        },
        item: {
            handler() {
                this.dirty = true;
            },
            deep: true,
        },
    },

    methods: {
        async saveChanges(): Promise<void> {
            try {
                this.saving = true;
                if (this.detailForm.validate()) {
                    await this.saveHandler(this.item);
                    this.$emit("showChange", false);
                } else {
                    this.validationMessage =
                        "Opravte chyby zadání a opakujte " +
                        (this.nested ? "potvrzení." : "uložení.");
                    this.showValidationMessage = true;
                }
            } catch (error) {
                this.validationMessage = error.message;
                this.showValidationMessage = true;
            } finally {
                this.saving = false;
                this.dirty = false;
            }
        },

        cancelChanges() {
            if (this.dirty) {
                this.showCancelConfirmationDialog = true;
            } else {
                this.innerCancelChanges();
            }
        },

        innerCancelChanges() {
            this.$emit("showChange", false);
            this.dirty = false;
        },

        preventDirtyNav(event: any): any {
            if (!this.dirty) {
                return;
            }

            event.preventDefault();
            event.returnValue = "";
        },
    },

    beforeMount() {
        window.addEventListener("beforeunload", this.preventDirtyNav);
    },

    beforeDestroy() {
        window.removeEventListener("beforeunload", this.preventDirtyNav);
    },
});
