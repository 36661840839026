import { OrderBy, OrderByDirection } from "@/models/orderBy";
import { Module } from "vuex";
import firebase from 'firebase/app';
import { db } from '../firebase';
import { Document } from '../models/document';
import { EditableObject } from "@/models/editableObject";
export class MutationNames {
    FETCH!: string;
    SET_METADATA!: string;
    ADD_ITEM!: string;
    UPDATE_ITEM!: string;
    REMOVE_ITEM!: string;

    constructor(init: Partial<MutationNames>) {
        Object.assign(this, init);
    }
}

export class CollectionModuleConfiguration {
    name!: string;
    collectionName!: string;
    firstOrderByName!: string;
    firstOrderByDirection!: OrderByDirection | null;
    filters!: object;
    mutationNames!: MutationNames;
    queryFactory!: (state: any, rootGetters: any) => firebase.firestore.Query
    entityConverter!: any;

    constructor(init: Partial<CollectionModuleConfiguration>) {
        Object.assign(this, init);
    }
}

export class CollectionModuleFactory {
    static create<T extends Document>(config: CollectionModuleConfiguration): Module<any, any> {
        const module: Module<any, any> = {};

        module.namespaced = true;

        module.state = {
            items: [],
            metadata: {
                first: null,
                last: null,
                page: 1,
                lastPage: 1,
                orderBy: new OrderBy({ name: config.firstOrderByName, direction: config.firstOrderByDirection || OrderByDirection.ASC }),
                filters: config.filters,
                loading: false,
                itemsPerPage: 0
            },
        };

        module.getters = {
            loading: (state) => {
                return state.metadata.loading;
            },
            canPrev: (state) => {
                return state.metadata.page > 1;
            },
            canNext: (state) => {
                return null != state.metadata.last;
            },
            orderByName: (state) => {
                return state.metadata.orderBy.name;
            },
            orderByDesc: (state) => {
                return state.metadata.orderBy.direction == OrderByDirection.DESC;
            },
            currentFilters: (state) => {
                return state.metadata.filters;
            }
        };

        module.mutations = {
            [config.mutationNames.FETCH](state, items) {
                state.items = [...items];
            },
            [config.mutationNames.SET_METADATA](state, metadata) {
                state.metadata = metadata;
            },
            [config.mutationNames.ADD_ITEM](state, item: T) {
                state.items.unshift(item);
            },
            [config.mutationNames.UPDATE_ITEM](state, updatedItem: T) {
                const original = state.items.filter((item: T) => item.id == updatedItem.id);
                if (original.length) {
                    (original[0] as EditableObject<T>).update(updatedItem);
                }
            },
            [config.mutationNames.REMOVE_ITEM](state, item: T) {
                state.items.splice(state.items.indexOf(item), 1);
            }
        };

        module.actions = {
            /**
             * Fetch collection with options if provided
             * @param param0 
             * @param options 
             */
            async fetch({ state, commit, rootGetters }, options: { itemsPerPage: number | null; filters: any | null }) {
                let first: firebase.firestore.DocumentSnapshot | null = null;
                let last: firebase.firestore.DocumentSnapshot | null = null;

                try {
                    const metadata = state.metadata;
                    metadata.loading = true;

                    if (options) {
                        if (options.itemsPerPage) {
                            metadata.itemsPerPage = options.itemsPerPage > 1 ? options.itemsPerPage : 1;
                        }

                        if (options.filters) {
                            metadata.filters = options.filters;
                            metadata.first = null;
                            metadata.last = null;
                            metadata.page = 1;
                            metadata.lastPage = 1;
                        }
                    }
                    commit(config.mutationNames.SET_METADATA, metadata);

                    const query = CollectionModuleFactory.prepareQuery(config.queryFactory, state, rootGetters);

                    const snapshot = await query.withConverter<T>(config.entityConverter).get();

                    const items = Array<T>();

                    snapshot.docs.forEach((doc: firebase.firestore.QueryDocumentSnapshot<T>) => {
                        items.push(doc.data());
                    });

                    if (snapshot.size > 0) {
                        first = snapshot.docs[0];

                        if (state.metadata.lastPage <= state.metadata.page) {
                            if (snapshot.size == state.metadata.itemsPerPage + 1) {
                                last = snapshot.docs[snapshot.size - 2];
                                items.pop();
                            }
                        } else {
                            last = snapshot.docs[snapshot.size - 1];
                        }
                    }

                    commit(config.mutationNames.FETCH, items);

                    commit(config.mutationNames.SET_METADATA, {
                        ...state.metadata,
                        loading: false,
                        first: first,
                        last: last
                    });
                } catch (error) {
                    console.log(error);
                    throw error;
                }
            },

            async goToPrevious({ state, commit }) {
                let page = state.metadata.page - 1;
                if (page < 1) {
                    page = 1;
                }
                commit(config.mutationNames.SET_METADATA, {
                    ...state.metadata,
                    lastPage: state.metadata.page,
                    page
                });
                await this.dispatch(`${config.name}/fetch`);
            },

            async goToNext({ state, commit }) {
                const page = state.metadata.page + 1;
                commit(config.mutationNames.SET_METADATA, {
                    ...state.metadata,
                    lastPage: state.metadata.page,
                    page
                });
                await this.dispatch(`${config.name}/fetch`);
            },

            /**
             * Reset pagination, set orderBy
             * @param param0 
             * @param options 
             */
            async orderBy({ state, commit }, options: { sortBy: string; sortDesc: boolean }) {
                const orderBy = state.metadata.orderBy;
                orderBy.name = options.sortBy;
                orderBy.direction = options.sortDesc ? OrderByDirection.DESC : OrderByDirection.ASC;

                commit(config.mutationNames.SET_METADATA, {
                    ...state.metadata,
                    ...CollectionModuleFactory.defaultMetadata(state, config, false, false),
                    orderBy
                });
                await this.dispatch(`${config.name}/fetch`);
            },

            /**
             * Reset pagination, hold orderby and filters and fetch
             * @param param0 
             */
            async refresh({ state, commit }) {
                commit(config.mutationNames.SET_METADATA, {
                    ...state.metadata,
                    ...CollectionModuleFactory.defaultMetadata(state, config, false, false),
                });
                await this.dispatch(`${config.name}/fetch`);
            },

            /**
             * Reset pagination, orderBy and filters. Without fetch
             * @param param0 
             */
            reset({ state, commit }) {                
                commit(config.mutationNames.SET_METADATA, {
                    ...state.metadata,
                    ...CollectionModuleFactory.defaultMetadata(state, config, true, true),
                });
            },

            async create({ state, commit }, item: T) {
                commit(config.mutationNames.SET_METADATA, {
                    ...state.metadata,
                    loading: true
                });
                const doc = await db.collection(config.collectionName)
                    .withConverter(config.entityConverter)
                    .add(item);
                item.id = doc.id;
                commit(config.mutationNames.ADD_ITEM, item);
                commit(config.mutationNames.SET_METADATA, {
                    ...state.metadata,
                    loading: false
                });
            },
            async update({ state, commit }, item: T) {
                commit(config.mutationNames.SET_METADATA, {
                    ...state.metadata,
                    loading: true
                });
                const doc = db.collection(config.collectionName).doc(item.id);
                await doc
                    .withConverter(config.entityConverter)
                    .set(item, { merge: true });
                commit(config.mutationNames.UPDATE_ITEM, item);
                commit(config.mutationNames.SET_METADATA, {
                    ...state.metadata,
                    loading: false
                });
            },
            async delete({ state, commit }, item: T) {
                commit(config.mutationNames.SET_METADATA, {
                    ...state.metadata,
                    loading: true
                });
                const doc = db.collection(config.collectionName).doc(item.id);
                await doc.delete();
                commit(config.mutationNames.REMOVE_ITEM, item);
                commit(config.mutationNames.SET_METADATA, {
                    ...state.metadata,
                    loading: false
                });
            }
        }

        return module;
    }

    private static defaultMetadata(state: any, config: any, resetOrderBy: boolean, resetFilters: boolean) {
        const metadata: any = {
            first: null,
            last: null,
            page: 1,
            lastPage: 1,
        }

        if(resetOrderBy) {
            const defaultOrderBy = state.metadata.orderBy;
            defaultOrderBy.name = config.firstOrderByName;
            defaultOrderBy.direction = OrderByDirection.ASC;
            metadata.orderBy = defaultOrderBy;
        }

        if(resetFilters) {
            const defaultFilters = config.filters;
            metadata.filters = defaultFilters;
        }

        return metadata;
    }

    private static prepareQuery(queryFactory: (state: any, rootGetters: any) => firebase.firestore.Query, state: any, rootGetters: any): firebase.firestore.Query {
        let query = queryFactory(state, rootGetters);
        if (state.metadata.lastPage <= state.metadata.page) {
            if (null != state.metadata.last) {
                query = query.startAfter(state.metadata.last);
            }
            query = query.limit(state.metadata.itemsPerPage + 1);
        } else {
            if (null != state.metadata.first) {
                query = query.endBefore(state.metadata.first);
            }
            query = query.limitToLast(state.metadata.itemsPerPage);
        }

        return query;
    }
}