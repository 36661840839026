
















import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { Order } from "../models/order";
import Utils from "../utils";
import pdf from "vue-pdf";

interface State {
    pdfLoading: boolean;
    source: any | null;
    pageCount: number;
}

export default Vue.extend({
    name: "OrderViewer",

    props: {
        order: Order,
    },

    components: {
        pdf,
    },

    data: (): State => {
        return {
            pdfLoading: false,
            source: null,
            pageCount: 0,
        };
    },

    computed: {
        ...mapGetters("config", ["apiKey"]),
    },

    watch: {
        order: {
            immediate: true,
            async handler() {
                if (this.order) {
                    await this.loadPdf();
                } else {
                    this.source = null;
                }
            },
        },
        apiKey: {
            async handler() {
                await this.loadPdf();
            },
        },
    },

    methods: {
        ...mapActions("alerts", ["addAlert"]),

        async loadPdf() {
            try {
                this.pdfLoading = true;
                if (this.apiKey) {
                    const loadingTask = pdf.createLoadingTask(await Utils.loadOrderPdfAsBase64(
                        this.order.id,
                        "sale",
                        this.apiKey
                    ));

                    const result = await loadingTask.promise;
                    this.pageCount = result.numPages;
                    this.source = loadingTask;                    
                }
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba exportu: " + error.message,
                });
            } finally {
                this.pdfLoading = false;
            }
        },
    },
});
