import { EditableObject } from './editableObject';
import { OrderPrice } from './orderPrice';

export const OUT_OF_VAT_RATE = 999;
export const ZERO_VAT_RATE = 0;

export class OrderTotal implements EditableObject<OrderTotal> {
    rates!: {
        [rate: string]: OrderPrice;
    };
    price!: OrderPrice;

    constructor(init: Partial<OrderTotal>) {
        Object.assign(this, init);
    }
    clone(): OrderTotal {
        return new OrderTotal({
            rates: Object.keys(this.rates).reduce<any>((rates: any, vatRateKey: string) => (
                rates[vatRateKey] = this.rates[vatRateKey].clone(),
                rates
            ), {}),
            price: this.price.clone()        
        })
    }
    update(orderTotal: OrderTotal): void {
        this.rates = orderTotal.rates;
        this.price = orderTotal.price;
    }
    clear() {
        Object.keys(this.rates).forEach(key => {
            this.rates[key].clear();
        });
        this.price.clear();
    }
}