














































import Vue from "vue";
import { mapActions } from "vuex";

export default Vue.extend({
    name: "Login",

    data: () => ({
        form: {
            valid: true,
            userName: {
                value: "",
                rules: [(v: any) => !!v || "Zadejte uživatelské jméno."],
            },
            password: {
                value: "",
                rules: [(v: any) => !!v || "Zadejte heslo."],
            },
        },
    }),

    computed: {
        formRef(): Vue & { validate: () => boolean } {
            return this.$refs.form as Vue & { validate: () => boolean };
        },
    },

    methods: {
        ...mapActions("alerts", ["addAlert"]),
        async login() {
            try {
                if (this.formRef.validate()) {
                    await this.$store.dispatch("user/login", {
                        username: this.form.userName.value,
                        password: this.form.password.value,
                    });
                }
            } catch (error) {
                let msg = "něco se pokazilo";
                if (
                    error.message == "The email address is badly formatted." ||
                    error.message ==
                        "The password is invalid or the user does not have a password."
                ) {
                    msg = "zadali jste špatné uživatelské jméno nebo heslo";
                } else {
                    console.log(error);
                }
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Přihlášení se nezdařilo: " + msg,
                    timeout: 3000
                });
            }
        },
    },
});
