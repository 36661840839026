














import Vue from "vue";
import { Address } from "../models/address";

export default Vue.extend({
  name: "AddressViewer",

  props: {
      address: Address,
      type: {
          type: String,
          default: 'oneline'
      }
  }
});
