import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

const firebaseConfig = {
    apiKey: "AIzaSyBS-GEy56sV0HIYjc2gsPMj0qfBVRtsR3g",
    authDomain: "mjc-velo.firebaseapp.com",
    projectId: "mjc-velo",
    storageBucket: "mjc-velo.appspot.com",
    messagingSenderId: "1028238809637",
    appId: "1:1028238809637:web:6deb20df254d391b553dcc",
    measurementId: "G-G5YG5FRJW0"
};

firebase.initializeApp(firebaseConfig);

if(process.env.NODE_ENV == 'development') {
    console.log('firebase using emulators');
    firebase.firestore().useEmulator("localhost", 8080);
    firebase.auth().useEmulator("http://localhost:9099");    
    firebase.functions().useEmulator("localhost", 5001);
}

const db = firebase.firestore();
const auth = firebase.auth();
const callBackendFunction = async (functionName: string, payload: object): Promise<any> => {
    const app = firebase.app();
    const functions = process.env.NODE_ENV == 'development' ? app.functions() : app.functions('europe-west3');
    const backendFunctionInvocation = functions.httpsCallable(`callableFunctions-${functionName}`);
    const result = await backendFunctionInvocation(payload);
    return result;
}

export {
    db, 
    auth,
    callBackendFunction
}